import { animated, useSpring } from '@react-spring/web'
import cls from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import { ModalsName } from 'models/Modals'
import ModalsStore from 'store/ModalsStore'
import { IconClose } from 'com/Icons'
import SignInForm from 'com/shared/Auth/SignInForm'
import s from './index.module.scss'

const Auth: React.FC = (): React.ReactElement => {
	const { t } = useTranslation('common')
	const [open, setOpen] = useState<boolean>(false)

	const handleClick = (): void => {
		ModalsStore.set(ModalsName.mobileMenu, false)
	}

	const handleClickSignIn = (): void => {
		setOpen(true)
	}

	const handleClickClose = (): void => {
		setOpen(false)
	}

	const styles = useSpring({
		opacity: open ? 1 : 0,
		maxHeight: open ? `500px` : '0px',
		config: { duration: 300 }
	})

	return (
		<div className={s.wrapper}>
			<div className={s.authLinks}>
				<NavLink to={AppRoutes.auth.signUp} onClick={handleClickSignIn}>
					{t('buttons.signIn')}
				</NavLink>
				<NavLink to={AppRoutes.auth.signIn} onClick={handleClick}>
					{t('buttons.signUp')}
				</NavLink>
			</div>

			<animated.div
				style={styles}
				className={cls(s.form, !open ? s.disableEvent : '')}
			>
				<div className={s.container}>
					<SignInForm />

					<span className={s.form__label}>or</span>

					<button
						className={s.buttonClose}
						onClick={handleClickClose}
					>
						<IconClose />
					</button>
				</div>
			</animated.div>
		</div>
	)
}
export default Auth
