import $api from 'api'
import {AxiosResponse} from 'axios'
import {ApiRoutes} from 'routes/ApiRoutes'
import {
    BalanceResponse,
    BalancesResponse,
    CurrenciesResponse,
    ExportedResponse,
    ImportArgs,
    ImportResponse,

} from './types'

export default class BanknotesService {
    static async balances(): Promise<AxiosResponse<BalancesResponse>> {
        return $api.get(ApiRoutes.user.balances)
    }

    static async import(
        args: ImportArgs
    ): Promise<AxiosResponse<ImportResponse>> {
        return $api.post(ApiRoutes.banknotes.import, args)
    }

    static async currencies(): Promise<AxiosResponse<CurrenciesResponse>> {
        return $api.get(ApiRoutes.currencies)
    }

    static async balance(
        currencyId: number | string
    ): Promise<AxiosResponse<BalanceResponse>> {
        return $api.get(`${ApiRoutes.user.balances}/${currencyId}`)
    }

    static async export(
        combination: any,
        key: string
    ): Promise<AxiosResponse<ExportedResponse>> {
        return $api.post(
            `${ApiRoutes.banknotes.export}/${key}`,
            combination
        )
    }
}
