import $api from 'api'
import { AxiosResponse } from 'axios'
import { ApiRoutes } from 'routes/ApiRoutes'
import {
	SecurityUpdateArgs,
	SecurityUpdateResponse,
	UserResponse
} from './UserService.types'

export default class UserService {
	static async user(): Promise<AxiosResponse<UserResponse>> {
		return $api.get<UserResponse>(ApiRoutes.user.user)
	}

	static async securityUpdate(
		args: SecurityUpdateArgs
	): Promise<AxiosResponse<SecurityUpdateResponse>> {
		return $api.post<SecurityUpdateResponse>(
			ApiRoutes.user.securityUpdate,
			args
		)
	}
}
