import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import MobileMenu from './MobileMenu'
import s from './index.module.scss'

const Layout: React.FC = (): React.ReactElement => {
	return (
		<div className={s.wrapper}>
			<Header />
			<MobileMenu />

			<div className={s.content}>
				<Outlet />
			</div>
		</div>
	)
}
export default observer(Layout)
