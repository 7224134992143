import classnames from 'classnames'
import styles from './styles.module.scss'

interface Props {
    label: string
    disabled?: boolean
    icon?: JSX.Element | string
    iconLink?: string
    onClick: () => void
    className?: string
    size?: 'small' | 'medium'
    notAbsolute?: boolean
}

const ActionButton: React.FC<Props> = (props: Props): React.ReactElement => {
    const {
        label,
        disabled,
        icon,
        iconLink,
        onClick,
        className,
        size,
        notAbsolute
    } = props

    return (
        <div
            className={classnames(
                size === 'small' || size === 'medium' ? styles.blockWrpap : '',
                notAbsolute ? styles.notAbsolute : ''
            )}
        >
            <button
                key={label}
                className={classnames(
                    size === 'small'
                        ? styles.blockSmall
                        : size === 'medium'
                        ? styles.blockMedium
                        : '',
                    disabled ? styles.disabledBlock : '',
                    className,
                    styles.block
                )}
                onClick={onClick}
            >
                {icon && <div>{icon}</div>}
                {iconLink && (
                    <div>
                        <img src={iconLink} alt='' />
                    </div>
                )}

                <p>{label}</p>
            </button>
        </div>
    )
}
export default ActionButton
