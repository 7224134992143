import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LSKey2FA, LSKeyAuthEmail } from 'constants/LocalStorageConstants'
import LocalStorageService from 'services/LocalStorageService'
import { AppRoutes } from 'routes/AppRoutes'
import { SignInSteps } from 'models/SignInSteps'
import AuthStore from 'store/AuthStore'
import SignInComponent from './component'

const SignIn: React.FC = (): React.ReactElement => {
	const navigate = useNavigate()

	useEffect(() => {
		if (AuthStore.isAuth) {
			navigate(AppRoutes.home)
		}
	}, [navigate, AuthStore.isAuth])

	useEffect(() => {
		const authEmail = LocalStorageService.get(LSKeyAuthEmail)
		if (authEmail) AuthStore.setStep(SignInSteps.emailConfirm)
	}, [])

	return <SignInComponent step={AuthStore.getStep()} />
}
export default observer(SignIn)
