import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import getSoftnoteImportErrorDesc from 'utils/getSoftnoteImportErrorDesc'
import Button from 'com/shared/buttons/Button'
import TextArea from 'com/shared/form/TextArea'
import styles from './styles.module.scss'
import BanknotesService from "services/Banknotes/BanknotesService";

interface Props {
    onClose: () => void
}

const ProModeForm: React.FC<Props> = props => {
    const [value, setValue] = useState<string>('')
    const { onClose } = props
    const { t: lang } = useTranslation('common')
    const { t: langImport } = useTranslation('import-modal')
    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const value = e.target.value
        setValue(value)
    }

    const handleClick = async (): Promise<void> => {
        if (value) {
            try {
                const stack = value.split('\n').map(item => {
                    const t = item.split('/')

                    return { [t[0]]: Number(t[1]) }
                })

                if (Array.isArray(stack) && stack.length >= 1) {
                    const response = await BanknotesService.import({ stack })

                    const { replenished, rejected } = response.data.payload

                    if (rejected && rejected.length >= 1) {
                        rejected.forEach(item =>
                            toast.error(
                                `${item.serial}. ${lang(
                                    'toasts.errors.error'
                                )}: ${getSoftnoteImportErrorDesc(item.error)}`
                            )
                        )
                    }

                    if (replenished && replenished.length >= 1) {
                        replenished.forEach(item =>
                            toast.success(
                                `${lang('toasts.success.successImport')}: ${
                                    item.amount
                                }${item.currency.ticker}`
                            )
                        )
                    }
                }
                setValue('')
                onClose()
            } catch (error) {
                console.log(error)
                //toast.error(lang('toasts.errors.invalidRequest'))
            }
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <TextArea
                    name='serialNumber'
                    value={value}
                    label={lang('labels.enterPasscode')}
                    onChange={onChange}
                />
                <div className={styles.text}>
                    {langImport('proMode.clue')}
                    {langImport('proMode.example')}: 000112345678790/12345
                </div>
            </div>
            <div className={styles.actions}>
                <Button onClick={onClose} size='large'>
                    {lang('buttons.cancel')}
                </Button>
                <Button onClick={handleClick} size='large' type='primary'>
                    {lang('buttons.import')}
                </Button>
            </div>
        </div>
    )
}

export default ProModeForm
