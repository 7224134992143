import { SignInSteps } from 'models/SignInSteps'
import Login from './com/Login'
import s from './component.module.scss'

interface Props {
	step: SignInSteps
}

const SignInComponent: React.FC<Props> = (props: Props): React.ReactElement => {
	const { step } = props

	return (
		<div className={s.wrapper}>
			<div className={s.container}>
				{step === SignInSteps.login && <Login />}
			</div>
		</div>
	)
}
export default SignInComponent
