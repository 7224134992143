import { useTranslation } from 'react-i18next'
import Checkbox from '../Checkbox'
import s from './index.module.scss'

interface Props {
	value: boolean
	onClick: () => void
}

const ShowPassword: React.FC<Props> = (props: Props): React.ReactElement => {
	const { value, onClick } = props
	const { t } = useTranslation('common')

	return (
		<div className={s.wrapper}>
			<Checkbox checked={value} onClick={onClick} />
			<span className={s.label}>
				{t('labels.showPassword')}
			</span>
		</div>
	)
}
export default ShowPassword
