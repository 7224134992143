import Layout from "com/layout"
import AppRedirectAfterAuth from 'com/shared/AppRedirectAfterAuth'
import AppLoader from 'com/UI/AppLoader'
import { LSKeyAccessToken } from 'constants/LocalStorageConstants'
import { observer } from 'mobx-react-lite'
import Page404 from 'pages/Page404'
import PasswordResetPage from 'pages/PasswordReset'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import SignIn from 'pages/SignIn'
import SignUpPage from 'pages/SignUp'
import UserSupportPage from 'pages/User/Support'
import AuthProvider from 'providers/AuthProvider'
import { Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import LocalStorageService from 'services/LocalStorageService'
import AuthStore from 'store/AuthStore'
import './App.css'
import DashboardPage from "./pages/Dashboard";


function App() {
	useEffect(() => {
		const accessToken = LocalStorageService.get(LSKeyAccessToken)
		
		if (accessToken) {
			AuthStore.checkAuth()
		}
	}, [])

	const routes = [
		{ path: AppRoutes.auth.signUp, element: <SignUpPage /> },
		{ path: AppRoutes.auth.signIn, element: <SignIn /> },
		{ path: AppRoutes.confidentiality.policy, element: <PrivacyPolicy /> },
		{ path: AppRoutes.auth.passwordReset, element: <PasswordResetPage /> }
		
	]

	const authRoutes = [
		{ path: AppRoutes.home, element: <DashboardPage /> },
		//{ path: AppRoutes.user.security, element: <UserSecurityPage /> },
		{ path: AppRoutes.user.support, element: <UserSupportPage /> }
	]

	return (
		<div className='App' data-version={'1.0.1'}>
            <AppRedirectAfterAuth/>
			<Suspense fallback={<AppLoader />}>
				{AuthStore.isLoading ? (
					<AppLoader />
				) : (
					<Routes>
						<Route element={<Layout />}>
							{routes.map(item => (
								<Route
									path={item.path}
									element={item.element}
								/>
							))}

							<Route element={<AuthProvider />}>
								{authRoutes.map(item => (
									<Route
										path={item.path}
										element={item.element}
									/>
								))}
							</Route>
						</Route>

						<Route path='*' element={<Page404 />} />
					</Routes>
				)}
			</Suspense>
		</div>
	)
}

export default observer(App)
