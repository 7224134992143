import $api from 'api'
import {AxiosResponse, AxiosStatic} from 'axios'
import {ResponseBase, ResponseValidate} from 'models/ResponseValidate'
import {ApiRoutes} from 'routes/ApiRoutes'
import {
    AuthEmailCodeCheckResponse,
    CheckCodeArgs,
    CheckCodeResponse,
    CreateOtkResponse,
    LoginArgs,
    LoginResponse,
    PreRegistrationArgs, RefreshTokenArgs,
    RegistrationArgs, SignUpApproveArgs,
    SocialNetworkArgs,
    SocialNetworkResponse
} from './AuthService.types'

export default class AuthService {
    static async login(
        args: LoginArgs
    ): Promise<AxiosResponse<LoginResponse>> {
        return $api.post<LoginResponse>(ApiRoutes.auth.signIn, args)
    }

    static async registration(
        args: RegistrationArgs
    ): Promise<AxiosResponse<ResponseValidate>> {
        return $api.post<ResponseValidate>(ApiRoutes.signUp.registration, args)
    }

    static async signUpApprove(
        args: SignUpApproveArgs
    ): Promise<AxiosResponse<ResponseBase>> {
        return $api.post<ResponseBase>(ApiRoutes.signUp.signUpApprove, args)
    }


    static async preRegistration(
        args: PreRegistrationArgs
    ): Promise<AxiosResponse<ResponseValidate>> {
        return $api.post(ApiRoutes.signUp.preRegistration, args)
    }

    static async refresh(
        args: RefreshTokenArgs
    ): Promise<AxiosResponse<LoginResponse>> {
        return $api.post<LoginResponse>(ApiRoutes.auth.refresh, args)
    }

    static async createOtk(): Promise<AxiosResponse<CreateOtkResponse>> {
        return $api.get<CreateOtkResponse>(ApiRoutes.auth.createOtk);
    }
}
