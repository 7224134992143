export const ApiRoutes = {
	signUp: {
		preRegistration: 'users/register/validate',
		signUpApprove: 'users/register',
		registration: 'registration'
	},
	auth: {
		signIn: 'users/login',
		refresh: 'token/refresh',
		createOtk: 'otk-create'
	},
	user: {
		user: 'users/info',
		balances: 'balances',
		securityUpdate: 'user/update'
	},
	banknotes:{
		baseurl: '/banknotes/',
		history: '/banknotes/history',
		import: '/banknotes/import',
		export: '/banknotes/export'
		
	},
	currencies: '/currencies',
	passwordReset: {
		request: 'users/recovery',
		checkCode: 'password-recovery/auth-email',
		changePassword: 'users/recovery/reset'
	}
}
