const IconTriangle: React.FC = (): React.ReactElement => {
	return (
		<svg
			width='13'
			height='13'
			viewBox='0 0 13 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5.93538 8.855L2.33838 4.7435C1.91388 4.25975 2.25888 3.5 2.90313 3.5H10.0971C10.2413 3.49988 10.3825 3.54132 10.5037 3.61936C10.625 3.6974 10.7211 3.80873 10.7807 3.94002C10.8403 4.07131 10.8608 4.217 10.8397 4.35963C10.8186 4.50227 10.7569 4.6358 10.6619 4.74425L7.06488 8.85425C6.99448 8.93481 6.90767 8.99937 6.81026 9.04361C6.71286 9.08785 6.60711 9.11074 6.50013 9.11074C6.39315 9.11074 6.28741 9.08785 6.19 9.04361C6.09259 8.99937 6.00578 8.93481 5.93538 8.85425V8.855Z'
				fill='#343C4D'
			/>
		</svg>
	)
}
export default IconTriangle
