import { TransitionFn, animated } from '@react-spring/web'
import UserCard from 'com/shared/user/UserCard'
import s from './MobileMenuComponent.module.scss'
import Auth from './com/Auth'
import Footer from './com/Footer'
import MainMenu from './com/MainMenu'
import ProductsMenu from './com/ProductsMenu'

interface Props {
	isAuth: boolean
	transition: TransitionFn<boolean, { right: string }>
}

const MobileMenuComponent: React.FC<Props> = (
	props: Props
): React.ReactElement => {
	const { isAuth, transition } = props

	return transition(
		(styles, state) =>
			state && (
				<animated.div style={styles} className={s.wrapper} key={1}>
					<div className={s.container}>
						{isAuth ? <UserCard /> : <Auth />}

						<MainMenu />
						<ProductsMenu />
						<Footer />
					</div>
				</animated.div>
			)
	)
}
export default MobileMenuComponent
