import {makeAutoObservable} from 'mobx'
import {
    LSKeyAccessToken,
    LSKeyAccessTokenExp,
    LSKeyAuthEmail,
    LSKeyRefreshToken,
    LSKeyRegLogin,
    LSKeyRegKey, LSKeyUserData
} from 'constants/LocalStorageConstants'
import AuthService from 'services/Auth/AuthService'
import {
    AuthEmailCodeCheckResponse,
    LoginArgs,
    LoginResponse,
    SocialNetworkOptions,
    SocialNetworkResponse
} from 'services/Auth/AuthService.types'
import LocalStorageService from 'services/LocalStorageService'
import UserService from 'services/User/UserService'
import {ModalsName} from 'models/Modals'
import {ResponseStatuses} from 'models/ResponseValidate'
import {SignInSteps} from 'models/SignInSteps'
import {Token} from 'models/Token'
import {User} from 'models/User'
import ModalsStore from './ModalsStore'
import SignUpStore from './SignUpStore'
import {SignUpSteps} from "../models/SignUpSteps";

class AuthStore {
    user: User | null = null
    step: SignInSteps = SignInSteps.login

    isAuth: boolean = false
    isLoading: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    setStep(step: SignInSteps): void {
        this.step = step
    }

    getStep(): SignInSteps {
        return this.step
    }

    async login(data: LoginArgs): Promise<LoginResponse | void> {
        try {
            const result = await AuthService.login(data)

            if (result.data.accessToken) {
                this.checkAccessResponse(result.data)
                return
            }

            LocalStorageService.set(LSKeyAuthEmail, data.login)
            this.setStep(SignInSteps.emailConfirm)

            return result.data
        } catch (error: any) {
            alert(error.message)
        }
    }


    logout(): void {
        LocalStorageService.remove(LSKeyAccessToken)
        LocalStorageService.remove(LSKeyRefreshToken)
        LocalStorageService.remove(LSKeyAccessTokenExp)
        this.setAuth(false)
        this.setUser(null)
    }

    async checkAuth() {
        this.setLoading(true)
        try {
            const refreshToken = LocalStorageService.get(LSKeyRefreshToken)
            const accessToken = LocalStorageService.get(LSKeyAccessToken)
            
            let accessTokenExp = LocalStorageService.get(LSKeyAccessTokenExp)
            if(accessTokenExp == null){
                accessTokenExp = 0
            }
            
            const currentDateExp: number = new Date().getTime()
                        
            if (accessTokenExp > currentDateExp) {
                this.setAuth(true)
                await this.getUser()
                return
            }

            const result = await AuthService.refresh({refreshToken: refreshToken, accessToken: accessToken})

            if (result.data) {
                this.checkAccessResponse(result.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            this.setLoading(false)
        }
    }


    async getUser() {
        try {
            if (!this.user) {
                 await UserService.user()
                    .then(res => {
                        if (!res.data.hasError) {
                            this.setUser(res.data)
                            LocalStorageService.set(LSKeyUserData, res.data)
                        }

                        SignUpStore.setStep(SignUpSteps.registration)
                    })

                    .catch(error => {
                        console.log(error.response.data)
                    })
                
            }
        } catch (error) {
            console.log(error)
            this.logout()
        }
    }

    async checkAccessResponse(data: LoginResponse) {
        if (data && data.accessToken) {
            LocalStorageService.set(LSKeyAccessToken, data.accessToken)
            LocalStorageService.set(LSKeyAccessTokenExp, data.expireIn)
            LocalStorageService.set(LSKeyRefreshToken, data.refreshToken)
            
            await this.getUser()
            this.setAuth(true)
        }
    }
    private setAuth(bool: boolean) {
        this.isAuth = bool
    }

    private setUser(user: User | null) {
        this.user = user
    }

    private setLoading(bool: boolean) {
        this.isLoading = bool
    }


}

export default new AuthStore()
