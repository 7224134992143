const IconHome: React.FC = (): React.ReactElement => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M20.1522 7.59123C20.8642 8.16388 21.2822 9.02539 21.2913 9.93906L21.2826 17.913C21.2826 20.0789 19.5268 21.8347 17.3609 21.8347H15.6217C14.5734 21.8347 13.7222 20.9874 13.7174 19.9391V16.3391C13.7174 16.0605 13.4916 15.8347 13.213 15.8347H10.6043C10.4691 15.8347 10.3394 15.889 10.2446 15.9855C10.1498 16.082 10.0977 16.2125 10.1 16.3478V17.7217C10.1 18.1539 9.74961 18.5043 9.31739 18.5043C8.88517 18.5043 8.53478 18.1539 8.53478 17.7217V16.3478C8.53478 15.7966 8.75374 15.2679 9.14349 14.8782C9.53324 14.4884 10.0619 14.2695 10.613 14.2695H13.2217C14.3661 14.2743 15.2913 15.2033 15.2913 16.3478V19.9391C15.2913 20.1215 15.4392 20.2695 15.6217 20.2695H17.4043C18.701 20.2695 19.7522 19.2183 19.7522 17.9217V9.95645C19.7458 9.51519 19.5415 9.10015 19.1957 8.82601L13.187 4.03471C12.4293 3.41091 11.336 3.41091 10.5783 4.03471L8.64783 5.47819C8.29004 5.73512 7.79172 5.65336 7.53478 5.29558C7.27785 4.93779 7.35961 4.43947 7.71739 4.18253L9.62174 2.79992C10.9421 1.73857 12.8231 1.73857 14.1435 2.79992L20.1522 7.59123ZM6.42174 20.2608H10.4217V20.2782C10.854 20.2782 11.2043 20.6286 11.2043 21.0608C11.2043 21.493 10.854 21.8434 10.4217 21.8434H6.42174C4.25781 21.8386 2.50478 20.0856 2.5 17.9217V9.95645C2.51384 9.03602 2.94588 8.17194 3.67391 7.60862L4.39565 7.06949C4.73552 6.90927 5.14129 7.0132 5.36229 7.31707C5.58329 7.62095 5.55714 8.039 5.3 8.31297L4.59565 8.83471C4.26191 9.11198 4.06776 9.52256 4.06522 9.95645V17.913C4.07474 19.2091 5.12562 20.2561 6.42174 20.2608Z'
				fill='#343C4D'
			/>
		</svg>
	)
}
export default IconHome
