import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import {
    createApi, fetchBaseQuery
} from '@reduxjs/toolkit/query/react';

const baseQueryArgs: FetchBaseQueryArgs = {
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
};

const baseQuery = fetchBaseQuery(baseQueryArgs);

export const rtkQApi = createApi({
    reducerPath: 'api',
    keepUnusedDataFor: 0,
    baseQuery,
    endpoints: () => ({}),
});