import classnames from 'classnames'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

interface Props {
    label: string
    link: string
    disabled?: boolean
    icon?: JSX.Element | string
    iconLink?: string
    size?: string
    notAbsolute?: boolean
}

const ActionLink: React.FC<Props> = (props: Props): React.ReactElement => {
    const { label, link, disabled, icon, iconLink, size } = props

    return (
        <div
            className={classnames(
                size === 'small' || size === 'medium' ? styles.blockWrpap : ''
            )}
        >
            <Link
                key={label}
                to={link}
                className={classnames(
                    size === 'small' ? styles.small : '',
                    size === 'medium' ? styles.medium : '',
                    styles.block,
                    disabled ? styles.disabledBlock : ''
                )}
            >
                {icon && <div>{icon}</div>}
                {iconLink && <img src={iconLink} alt='' />}

                <p>{label}</p>
            </Link>
        </div>
    )
}
export default ActionLink
