import axios, {AxiosError, AxiosRequestConfig} from 'axios'
import {
    LSKeyAccessToken,
    LSKeyLang,
    LSKeyRefreshToken
} from 'constants/LocalStorageConstants'
import AuthService from 'services/Auth/AuthService'
import LocalStorageService from 'services/LocalStorageService'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

const $api = axios.create({
    // withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-type': 'application/json',
        'lang': 'en'
    }
})


const refreshAuthLogic = (failedRequest: any) => {
    console.error('refreshAuthLogic', failedRequest.config.url)

    let axiosError: AxiosError | null = null
    if (axios.isAxiosError(failedRequest)) {
        axiosError = failedRequest as AxiosError
    }
    if (axiosError === null) return Promise.reject(failedRequest)

    if (axiosError.response?.status !== 401)
        return Promise.reject(failedRequest)
    if (axiosError.config?.url?.indexOf('token/refresh') !== -1)
        return Promise.reject(failedRequest)
    if (axiosError.request.responseURL?.indexOf('token/refresh') !== -1)
        return Promise.reject(failedRequest)

    const refreshToken = LocalStorageService.get(LSKeyRefreshToken)
    
    return AuthService.refresh(refreshToken).then(result => {
        if (result) {
            LocalStorageService.set(
                LSKeyAccessToken,
                result.data.accessToken
            )
            LocalStorageService.set(
                LSKeyRefreshToken,
                result.data.refreshToken
            )

            failedRequest.response.config.headers.Authorization = 'Bearer ' + result.data.accessToken
            return Promise.resolve()
        }
    }).catch(err => {
        // if get 4xx or 5xx remove refresh and access token
        if (err.response) {
            if (err.response.status === 500) {
                return;
            }
            
            LocalStorageService.remove(LSKeyAccessToken)
            LocalStorageService.remove(LSKeyRefreshToken)
        }
    })
}

function useAccessToken(config: AxiosRequestConfig) {
    const accessToken = LocalStorageService.get(LSKeyAccessToken)

    if (!config.headers) config.headers = {}
    const language = LocalStorageService.get(LSKeyLang)

    if (accessToken) {
        config.headers.Authorization = 'Bearer ' + accessToken
    }
    config.headers.lang = language ?? 'en'

    return config
}


createAuthRefreshInterceptor($api, refreshAuthLogic, {
    pauseInstanceWhileRefreshing: true
})
$api.interceptors.request.use((config: AxiosRequestConfig) =>
    useAccessToken(config)
)


// $api.interceptors.response.use(
//     config => {
//         return config
//     },
//     async error => {
//         const originalRequest = error.config
//
//
//         // if (Number(error.response.status) === 400) 
// 		// {
//         //    
//         //      let axiosError: AxiosError | null = null
//         //
// 		// 	 if (axios.isAxiosError(error)) {
// 		// 	 	axiosError = error as AxiosError
// 		// 	 }
//         //    
//         //      if (axiosError === null) return Promise.reject(error)
// 		//	
//         //     let res = Promise.resolve();
//         //     console.log(res)
// 		// 	return res;
//         // }
//
//         if(error.data.hasError){
//             return error.data
//         }
//        
//
//         if (
//             Number(error.response.status) === 401 &&
//             error.config &&
//             !error.config._isRetry
//         ) {
//             originalRequest._isRetry = true
//             try {
//                 const refreshToken = LocalStorageService.get(LSKeyRefreshToken)
//                 const result = await AuthService.refresh(refreshToken)
//
//                 const {hasError, payload} = result.data
//
//                 if (!hasError && payload.accessToken) {
//                     LocalStorageService.set(
//                         LSKeyAccessToken,
//                         payload.accessToken
//                     )
//                     LocalStorageService.set(
//                         LSKeyRefreshToken,
//                         payload.refreshToken
//                     )
//                     return $api.request(originalRequest)
//                 }
//             } catch (error) {
//                 console.log('Not auth', error)
//             }
//         }
//        
//      return error.data;
//     }
// )

// $api.interceptors.request.use((config: AxiosRequestConfig) => {
//     const accessToken = LocalStorageService.get(LSKeyAccessToken)
//
//     if (!config.headers) config.headers = {}
//     const language = LocalStorageService.get(LSKeyLang)
//
//     config.headers.Authorization = 'Bearer ' + accessToken
//     config.headers.lang = language ?? 'en'
//
//     console.log(config.headers)
//
//
//     return config
// })
export default $api
