import { FormikProps, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LSKeyPaswordResetSK } from 'constants/LocalStorageConstants'
import LocalStorageService from 'services/LocalStorageService'
import { AppRoutes } from 'routes/AppRoutes'
import { PasswordResetSteps } from 'models/PasswordResetSteps'
import { ResponseStatuses } from 'models/ResponseValidate'
import PasswordResetStore from 'store/PasswordResetStore'
import ChangePasswordComponent from './component'
import { Values } from './types'
import validationSchema from './validationSchema'

const ChangePassword: React.FC<WithTranslation> = ({
	t
}): React.ReactElement => {
	const [isLoading, setLoading] = useState<boolean>(false)
	const [validateOnChange, setValidateOnChange] = useState<boolean>(false)
	const [isShowPassword, setShowPassword] = useState<boolean>(false)

	const navigate = useNavigate()

	useEffect(() => {
		// const SK = LocalStorageService.get(LSKeyPaswordResetSK)
		// if (!SK || (SK && SK.length !== 64)) {
		// 	PasswordResetStore.setStep(PasswordResetSteps.request)
		// }
	}, [])

	const formik: FormikProps<Values> = useFormik<Values>({
		initialValues: {} as Values,
		validationSchema: validationSchema(),
		validateOnChange: validateOnChange,

		onSubmit: async (values: Values) => {
			setLoading(true)

			const result = await PasswordResetStore.changePassword(
				values.password
				
			)

			setLoading(false)

			if (result && result.hasError) {
				formik.setFieldError('confirm_password', String(result.errorMsgs.join("\n")))
			}

			if (result && !result.hasError) {
				navigate(AppRoutes.auth.signIn)
			}
		}
	})

	const handleClickBack = () => {
		PasswordResetStore.setStep(PasswordResetSteps.request)
	}

	const handleSubmit = () => {
		setValidateOnChange(true)
		formik.handleSubmit()
	}

	const handleClickShowPassword = () => {
		setShowPassword(prev => !prev)
	}

	const login = PasswordResetStore.getLogin()
	
	return (
		<ChangePasswordComponent
			formik={formik}
			handleClickBack={handleClickBack}
			isLoading={isLoading}
			handleSubmit={handleSubmit}
			isShowPassword={isShowPassword}
			handleClickShowPassword={handleClickShowPassword}
			t={t}
		    login={login}/>
	)
}
export default withTranslation(['auth', 'common'])(ChangePassword)
