import Loader from "../Loader"

const AppLoader: React.FC = (): React.ReactElement => {
	return (
		<div className='App-loader'>
			<Loader />
		</div>
	)
}
export default AppLoader
