import { SignUpSteps } from 'models/SignUpSteps'
import PreRegistration from './com/PreRegistration'
import Registration from './com/Registration'
import s from './component.module.scss'
import React from "react";
import SignUpApprove from "./com/SignUpApprove";

interface Props {
	step: SignUpSteps
}

const SignUpPageComponent: React.FC<Props> = (
	props: Props
): React.ReactElement => {
	const { step } = props

	return (
		<div className={s.wrapper}>
			<div className={s.container}>
				{step === SignUpSteps.preRegistration && <PreRegistration />}
				{step === SignUpSteps.signUpApprove && <SignUpApprove />}
				{step === SignUpSteps.registration && <Registration />}
			</div>
		</div>
	)
}
export default SignUpPageComponent
