import {useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import RegistrationComponent from './component'

const Registration: React.FC<WithTranslation> = ({t}): React.ReactElement => {
    const [isLoading, setLoading] = useState<boolean>(false)

    return (
        <RegistrationComponent
            isLoading={isLoading}
            t={t}
        />
    )
}
export default withTranslation(['auth', 'common'])(Registration)
