import { AppConfig } from './types'

export const useConfig = (): AppConfig => {
    return {
        resourcesUrl: 'https://src.softnote.com/',
        authApiUrl: process.env.REACT_APP_API_URL!,
        softnoteApiUrl: process.env.REACT_APP_SOFTNOTE_API_URL!,
        cloudApiUrl: process.env.REACT_APP_CLOUD_API_URL!,
        tectumPassportUrl: process.env.REACT_APP_TECTUM_PASSPORT_URL!,
    }
}
