import {observer} from 'mobx-react-lite'
import Skeleton from 'com/UI/Skeleton'
import CurrencyBlock from 'com/shared/CurrencyBlock'
import styles from './styles.module.scss'
import BanknotesStore from "store/Banknotes/BanknotesStore";
import {useEffect} from "react";

const CurrenciesBalances: React.FC = (): React.ReactElement => {
    const {balances, isLoadingBalances} = BanknotesStore
    useEffect(() => {
        if (balances.length==0) {
            BanknotesStore.cacheBalances()
        }
    }, [balances])

    return (
        <div className={styles.wrapper}>
            {isLoadingBalances ? (
                <Skeleton/>
            ) : (
                balances?.map(item => (
                    <CurrencyBlock key={item.id} info={item}/>
                ))  
            )}
        </div>

    )
}
export default observer(CurrenciesBalances)
