import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import s from './index.module.scss'

const PrivacyPolicySign: React.FC = (): React.ReactElement => {
	const { t } = useTranslation('common')
	return (
		<div className={s.policy}>
			<p>2022 Tectum ® |</p>
			<Link to={AppRoutes.confidentiality.policy}>
				{t('labels.privacyPolicy')}
			</Link>
		</div>
	)
}
export default PrivacyPolicySign
