import Field from 'com/shared/form/Field'
import s from './component.module.scss'

interface Props {}

const UserSupportPageComponent: React.FC<Props> = (
	props: Props
): React.ReactElement => {
	return (
		<section className={s.wrapper}>
			<div className={s.container}>
				
				<div className={s.form}>
					<Field name='Theme' value='' placeholder='Theme' />

					<textarea></textarea>

					<button>Send</button>
				</div>
			</div>
		</section>
	)
}
export default UserSupportPageComponent
