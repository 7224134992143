import { TransitionFn, animated } from '@react-spring/web'
import { MutableRefObject } from 'react'
import { Link } from 'react-router-dom'
import IconLogo from 'assets/icons/logo_softnote.svg'
import IconUser from 'assets/icons/user/user.svg'
import { AppRoutes } from 'routes/AppRoutes'
import { IconSettings } from 'com/Icons'
import UserCard from 'com/shared/user/UserCard'
import s from './HeaderComponent.module.scss'

interface Props {
    isAuth: boolean
    transition: TransitionFn<boolean, { opacity: string }>
    handleClickUserInfo: () => void
    refUserCard: MutableRefObject<HTMLDivElement>
}

const HeaderComponent: React.FC<Props> = (props: Props): React.ReactElement => {
    const { isAuth, transition, handleClickUserInfo, refUserCard } = props

    return (
        <header className={s.wrapper}>
            <div className={s.container}>
                <div className={s.leftSide}>
                    <Link to={AppRoutes.home} className={s.logo}>
                        <img src={IconLogo} alt='Vault logo' />
                    </Link>
                </div>

                <div className={s.rightSide}>
                    {isAuth && (
                        <button
                            className={s.user}
                            onClick={handleClickUserInfo}
                        >
                            <img src={IconUser} alt='' />
                        </button>
                    )}
                </div>

                {transition(
                    (styles, state) =>
                        state && (
                            <animated.div
                                style={styles}
                                className={s.userInfoPopup}
                                key={1}
                                ref={refUserCard}
                            >
                                <UserCard />
                                <Link
                                    to={AppRoutes.settings}
                                    className={s.userInfoPopup__button}
                                >
                                    <IconSettings />
                                    settings
                                </Link>
                            </animated.div>
                        )
                )}
            </div>
        </header>
    )
}
export default HeaderComponent
