import { PasswordResetSteps } from 'models/PasswordResetSteps'
import ChangePassword from './com/ChangePassword'
import RequestReset from './com/RequestReset'
import s from './component.module.scss'

interface Props {
	step: PasswordResetSteps
}

const PasswordResetPageComponent: React.FC<Props> = (
	props: Props
): React.ReactElement => {
	const { step } = props
	return (
		<div className={s.wrapper}>
			<div className={s.container}>
				{step === PasswordResetSteps.request && <RequestReset />}
				{step === PasswordResetSteps.resetPassword && (
					<ChangePassword />
				)}
			</div>
		</div>
	)
}
export default PasswordResetPageComponent
