import { FormikProps } from 'formik'
import { TFunction } from 'react-i18next'
import Loader from 'com/UI/Loader'
import FormTitle from 'com/UI/form/FormTitle'
import Field from 'com/shared/form/Field'
import ShowPassword from 'com/shared/form/ShowPassword'
import s from './component.module.scss'
import { Values } from './types'

interface Props {
	formik: FormikProps<Values>
	handleClickBack: () => void
	isLoading: boolean
	handleSubmit: () => void
	isShowPassword: boolean
	handleClickShowPassword: () => void
	login: string
	t: TFunction
}

const ChangePasswordComponent: React.FC<Props> = (
	props: Props
): React.ReactElement => {
	const {
		formik,
		handleClickBack,
		isLoading,
		handleSubmit,
		isShowPassword,
		handleClickShowPassword,
		login,
		t
	} = props

	return (
		<>
			<FormTitle>{t('passwordReset.changePassword.title')}</FormTitle>
			<div>{t('passwordReset.changePassword.login')}: {login}</div>
			<div className={s.form}>
				<Field
					type={isShowPassword ? 'text' : 'password'}
					name='password'
					value={formik.values.password || ''}
					onChange={formik.handleChange}
					error={formik.errors.password}
					placeholder={t('fields.password.placeholder', {
						ns: 'common'
					})}
				/>

				<Field
					type={isShowPassword ? 'text' : 'password'}
					name='confirm_password'
					value={formik.values.confirm_password || ''}
					onChange={formik.handleChange}
					error={formik.errors.confirm_password}
					placeholder={t('fields.confirmPassword.placeholder', {
						ns: 'common'
					})}
				/>

				<Field
					type={'hidden'}
					name='seedPhrase'
					value={formik.values.seedPhrase || ''}
				/>

				<ShowPassword
					value={isShowPassword}
					onClick={handleClickShowPassword}
				/>

				<div className={s.actions}>
					<button className={s.backButton} onClick={handleClickBack}>
						{t('buttons.back', { ns: 'common' })}
					</button>

					<button
						className={s.nextButton}
						onClick={handleSubmit}
						type='submit'
					>
						{isLoading ? (
							<Loader size='small' />
						) : (
							t('buttons.changePassword', { ns: 'common' })
						)}
					</button>
				</div>
			</div>
		</>
	)
}
export default ChangePasswordComponent
