const IconUserVerification: React.FC = (): React.ReactElement => {
	return (
		<svg
			width='18'
			height='13'
			viewBox='0 0 18 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5.26984 6.52237C6.99089 6.52237 8.28922 5.22756 8.28922 3.51118C8.28922 1.79481 6.99089 0.5 5.26984 0.5C3.54879 0.5 2.25045 1.79481 2.25045 3.51118C2.25045 5.22756 3.54879 6.52237 5.26984 6.52237ZM6.13252 7.33797H4.40716C2.02874 7.33797 0.09375 9.26771 0.09375 11.6397V12.5H10.4459V11.6397C10.4459 9.26771 8.51093 7.33797 6.13252 7.33797ZM15.8756 3.2875L12.1661 6.98007L11.0515 5.86851L9.8317 7.08503L12.1661 9.41138L17.0938 4.50574L15.8756 3.2875Z'
				fill='#2261F6'
			/>
		</svg>
	)
}
export default IconUserVerification
