import { IconHome } from 'com/Icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import s from './index.module.scss'

const MainMenu: React.FC = (): React.ReactElement => {
	const { t } = useTranslation('common')
	return (
		<div className={s.nav}>
			<Link to={AppRoutes.home}>
				<a className={s.nav__label}>
					<IconHome />
					{t('menu.home')}
				</a>
			</Link>

			<div className={s.nav__list}>
				<a target="_blank" href="https://wallet.softnote.com/" rel="noreferrer">
					{t('menu.tectumWallet')}
				</a>
				<a target="_blank" href="https://wallet.softnote.com/mint-house" rel="noreferrer">{t('menu.mintHouse')}</a>
				<a target="_blank" href="https://wallet.softnote.com/exchange" rel="noreferrer">{t('menu.exchange')}</a>
				{/* <Link to={AppRoutes.explorer}>{t('menu.explorer')}</Link>
				<Link to={AppRoutes.settings}>{t('menu.settings')}</Link> */}
			</div>
		</div>
	)
}
export default MainMenu
