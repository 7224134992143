import * as Yup from 'yup'

const validationSchema = () =>
    Yup.object().shape({
        login: Yup.string().min(8).max(32).required(),

        password: Yup.string()
            .min(8)
            .max(255)
            .required(),

        password_repeat: Yup.string()
            .min(8)
            .max(64)
            .required()
            .oneOf([Yup.ref('password'), null]),

        token: Yup.string().required(),
    })

export default validationSchema
