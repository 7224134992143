import { animated, useTransition } from '@react-spring/web'
import { useMediaQuery } from 'hooks/useMediaQuery'
import useOnClickOutside from 'hooks/useOnClickOutside'
import useScrollBlock from 'hooks/useScrollBlock'
import { useEffect, useRef } from 'react'
import { MEDIA_QUERY_MOBILE } from 'utils/constants/MediaQueryConstants'
import {
    ReactSpringModalOptions,
    ReactSpringSlideOptions
} from 'utils/reactSpringOptions/modal'
import styles from './styles.module.scss'

interface Props {
    children: React.ReactNode
    open: boolean
    onClose: () => void
}

const ModalWrapper: React.FC<Props> = (props: Props): React.ReactElement => {
    const { children, open, onClose } = props
    const [blockScroll, allowScroll] = useScrollBlock()
    const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)
    const transition = useTransition(open, ReactSpringModalOptions)
    const transitionSlide = useTransition(open, ReactSpringSlideOptions)

    const refModel = useRef() as React.MutableRefObject<HTMLDivElement>
    useOnClickOutside(refModel, onClose)

    useEffect(() => {
        open ? blockScroll() : allowScroll()

        return () => {
            allowScroll()
        }
    }, [open, blockScroll, allowScroll])

    if (isMobile) {
        return (
            <>
                {transitionSlide(
                    (tStyles, state) =>
                        state && (
                            <animated.div
                                style={tStyles}
                                className={styles.wrapper}
                            >
                                <div className={styles.container}>
                                    {transition(
                                        (tStyles, state) =>
                                            state && (
                                                <animated.div style={tStyles}>
                                                    {children}
                                                </animated.div>
                                            )
                                    )}
                                </div>
                            </animated.div>
                        )
                )}
            </>
        )
    }

    return (
        <>
            {transition(
                (tStyles, state) =>
                    state && (
                        <animated.div
                            style={tStyles}
                            className={styles.wrapper}
                        >
                            <div className={styles.container} ref={refModel}>
                                {transition(
                                    (tStyles, state) =>
                                        state && (
                                            <animated.div style={tStyles}>
                                                {children}
                                            </animated.div>
                                        )
                                )}
                            </div>
                        </animated.div>
                    )
            )}
        </>
    )
}
export default ModalWrapper
