const IconUserProAccount: React.FC = (): React.ReactElement => {
	return (
		<svg
			width='15'
			height='13'
			viewBox='0 0 15 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.09375 0.5C5.72653 0.5 5.37434 0.645879 5.11468 0.905545C4.85501 1.16521 4.70913 1.51739 4.70913 1.88462V2.34615H1.47837C1.11114 2.34615 0.75896 2.49203 0.499294 2.7517C0.239629 3.01136 0.09375 3.36355 0.09375 3.73077L0.09375 5.00831L7.12206 6.88215C7.35553 6.94433 7.6012 6.94433 7.83467 6.88215L14.863 5.00831V3.73077C14.863 3.36355 14.7171 3.01136 14.4574 2.7517C14.1978 2.49203 13.8456 2.34615 13.4784 2.34615H10.2476V1.88462C10.2476 1.51739 10.1017 1.16521 9.84205 0.905545C9.58239 0.645879 9.2302 0.5 8.86298 0.5H6.09375ZM6.09375 1.42308H8.86298C8.98539 1.42308 9.10278 1.4717 9.18934 1.55826C9.27589 1.64481 9.32452 1.76221 9.32452 1.88462V2.34615H5.63221V1.88462C5.63221 1.76221 5.68084 1.64481 5.76739 1.55826C5.85395 1.4717 5.97134 1.42308 6.09375 1.42308V1.42308Z'
				fill='#686868'
			/>
			<path
				d='M0.09375 11.1153C0.09375 11.4825 0.239629 11.8347 0.499294 12.0944C0.75896 12.354 1.11114 12.4999 1.47837 12.4999H13.4784C13.8456 12.4999 14.1978 12.354 14.4574 12.0944C14.7171 11.8347 14.863 11.4825 14.863 11.1153V5.8999L7.59744 7.83559C7.51942 7.85643 7.43731 7.85643 7.35929 7.83559L0.09375 5.8999V11.1153Z'
				fill='#686868'
			/>
		</svg>
	)
}
export default IconUserProAccount
