import {FormikProps} from 'formik'
import {GoogleReCaptcha} from 'react-google-recaptcha-v3'
import {TFunction} from 'react-i18next'
import Loader from 'com/UI/Loader'
import FormTabs from 'com/UI/form/FormTabs'
import FormTitle from 'com/UI/form/FormTitle'
import Field from 'com/shared/form/Field'
import s from './component.module.scss'
import {Values} from './types'

interface Props {
    formik: FormikProps<Values>
    isLoading: boolean
    handleSubmit: () => void
    t: TFunction
    refreshReCaptcha: boolean
    onChangeCaptcha: (value: string | null) => void
    errors: string[]
}

const PreRegistrationComponent: React.FC<Props> = (
    props: Props
): React.ReactElement => {
    const {
        formik,
        isLoading,
        handleSubmit,
        t,
        refreshReCaptcha,
        onChangeCaptcha,
        errors
    } = props

    return (
        <>
            <FormTitle>{t('preRegister.title')}</FormTitle>
            
            <FormTabs/>

            <div className={s.form}>
                { errors.length>0 ? (
                <div className="errorMessage">
                    {errors.map((item, index) =>
                        {
                            if (index === 0) {
                                return <>{item}</>
                            }
                            return <><br/><br/>{item}</>
                        }
                    )
                    }
                </div>
                    ):( <></>  )
                }
                <Field
                    type='text'
                    name='login'
                    value={formik.values.login || ''}
                    onChange={formik.handleChange}
                    error={formik.errors.login}
                    placeholder={t('preRegister.fields.login.placeholder')}
                />

                <Field
                    type='password'
                    name='password'
                    value={formik.values.password || ''}
                    onChange={formik.handleChange}
                    error={formik.errors.password}
                    placeholder={t('preRegister.fields.password.placeholder')}
                />

                <Field
                    type='password'
                    name='password_repeat'
                    value={formik.values.password_repeat || ''}
                    onChange={formik.handleChange}
                    error={formik.errors.password_repeat}
                    placeholder={t('preRegister.fields.password_repeat.placeholder')}
                />

                <button className={s.submitButton} onClick={handleSubmit}>
                    {isLoading ? (
                        <Loader size='small'/>
                    ) : (
                        t('buttons.createAccount', {ns: 'common'})
                    )}
                </button>

                <GoogleReCaptcha
                    refreshReCaptcha={refreshReCaptcha}
                    onVerify={onChangeCaptcha}
                />
            </div>
        </>
    )
}
export default PreRegistrationComponent
