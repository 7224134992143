import {FormikProps} from 'formik'
import {TFunction} from 'react-i18next'
import Loader from 'com/UI/Loader'
import FormTitle from 'com/UI/form/FormTitle'
import s from './component.module.scss'
import {Values} from './types'
import TextArea from "com/shared/form/TextArea";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";

interface Props {
    formik: FormikProps<Values>
    isLoading: boolean
    handleSubmit: () => void
    handleClickBack: () => void
    errors: string[]
    t: TFunction,
    onChangeCaptcha: (value: string | null) => void
    refreshReCaptcha: boolean
}

const RequestResetComponent: React.FC<Props> = (
    props: Props
): React.ReactElement => {
    const {
        formik, isLoading,
        handleSubmit,
        handleClickBack,
        t,
        onChangeCaptcha,
        refreshReCaptcha,
        errors
    } = props

    return (
        <>
            <FormTitle>{t('passwordReset.requestReset.title')}</FormTitle>
            {errors && errors.length > 0 ? (
                <div className="errorMessage">
                    {errors.map((item, index) => {
                            if (index === 0) {
                                return <>{item}</>
                            }
                            return <><br/><br/>{item}</>
                        }
                    )
                    }
                </div>
            ) : (<></>)
            }

            <div className={s.form}>
                <TextArea
                    name='seedPhrase'
                    value={formik.values.seedPhrase || ''}
                    placeholder={t('passwordReset.fields.seedPhrase.placeholder')}
                    onChange={formik.handleChange}
                    readOnly={false}
                />

                <div className={s.actions}>
                    <button className={s.backButton} onClick={handleClickBack}>
                        {t('buttons.back', {ns: 'common'})}
                    </button>
                    <button className={s.submitButton} onClick={handleSubmit}>
                        {isLoading ? (
                            <Loader size='small'/>
                        ) : (
                            t('buttons.passwordReset', {ns: 'common'})
                        )}
                    </button>
                </div>
                <GoogleReCaptcha
                    refreshReCaptcha={refreshReCaptcha}
                    onVerify={onChangeCaptcha}
                />
            </div>
        </>
    )
}
export default RequestResetComponent
