import { FormikProps } from 'formik'
import { ChangeEvent } from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { TFunction } from 'react-i18next'
import Loader from 'com/UI/Loader'
import ForgotPasswordButton from 'com/UI/buttons/ForgotPasswordButton'
import Field from 'com/shared/form/Field'
import ShowPassword from 'com/shared/form/ShowPassword'
import s from './component.module.scss'
import { Values } from './types'

interface Props {
	formik: FormikProps<Values>
	isLoading: boolean
	handleSubmit: () => void
	isShowPassword: boolean
	handleClickShowPassword: () => void
	t: TFunction
	handleChangeEmail: (e: ChangeEvent<HTMLInputElement>) => void
	onChangeCaptcha: (value: string | null) => void
	refreshReCaptcha: boolean
	errors: string []
}

const SignInFormComponent: React.FC<Props> = (
	props: Props
): React.ReactElement => {
	const {
		formik,
		isLoading,
		handleSubmit,
		isShowPassword,
		handleClickShowPassword,
		handleChangeEmail,
		t,
		onChangeCaptcha,
		refreshReCaptcha,
		errors
	} = props

	return (
		<div className={s.form}>
			{ errors.length>0 ? (
				<div className="errorMessage">
					{errors.map((item, index) =>
						{
							if (index === 0) {
								return <>{item}</>
							}
							return <><br/><br/>{item}</>
						}
					)
					}
				</div>
			):( <></>  )
			}
			<Field
				type='text'
				name='login'
				value={formik.values.login || ''}
				onChange={handleChangeEmail}
				error={formik.errors.login}
				placeholder={t('signIn.fields.login.placeholder')}
			/>

			<Field
				type={isShowPassword ? 'text' : 'password'}
				name='password'
				value={formik.values.password || ''}
				onChange={formik.handleChange}
				error={formik.errors.password}
				placeholder={t('signIn.fields.password.placeholder')}
			/>
			<div className={s.actions}>
				<ShowPassword
					value={isShowPassword}
					onClick={handleClickShowPassword}
				/>

				<ForgotPasswordButton />
			</div>
			<GoogleReCaptcha
				refreshReCaptcha={refreshReCaptcha}
				onVerify={onChangeCaptcha}
			/>
			<button
				className={s.submitButton}
				onClick={handleSubmit}
				type='button'
			>
				{isLoading ? (
					<Loader size='small' />
				) : (
					t('buttons.logIn', { ns: 'common' })
				)}
			</button>
		</div>
	)
}
export default SignInFormComponent
