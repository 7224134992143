import * as Yup from 'yup'

const validationSchema = () =>
	Yup.object({
		login: Yup.string().required(),
		password: Yup.string().max(255).required(),
		token: Yup.string().required()
	})

export default validationSchema
