import {Link} from 'react-router-dom'
import {capitalize} from 'utils/capitalize'
import CurrencyImage from 'com/UI/CurrencyImage'
import styles from './styles.module.scss'
import {BalanceBanknote} from "store/Banknotes/BanknotesStore";
import {formatCryptoBalance} from "utils/formatCryptoBalance";
import {ApiRoutes} from "routes/ApiRoutes";

interface Props {
    info: BalanceBanknote
    link?: string | null
}

const CurrencyBlock: React.FC<Props> = (props: Props): React.ReactElement => {
    const {info, link} = props

    return (
        <div className={styles.wrapper}>
            <Link to={ApiRoutes.banknotes.baseurl+info.key.toLowerCase()} className={styles.header}>
                <div className={styles.headerCurrency}>
                    <CurrencyImage ticker={info.ticker}/>
                    <div>
                        <span className={styles.ticker}>{info.ticker}</span>
                        <span>
                            {capitalize(info.network)} ({info.protocol})
                        </span>
                    </div>
                </div>
                <div className={styles.headerInfo}>
                    <span>
                        {formatCryptoBalance(info.amount, info.decimalPlaces == 0 ? 4 : info.decimalPlaces)}{' '}
                        <b>{info.ticker}</b>
                    </span>
                    <span>{info.count} bills</span>
                </div>
            </Link>
            <div className={styles.footer}>
            </div>
        </div>
    )
}
export default CurrencyBlock
