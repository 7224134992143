import SignInForm from 'com/shared/Auth/SignInForm'
import FormTabs from 'com/UI/form/FormTabs'
import FormTitle from 'com/UI/form/FormTitle'
import { useTranslation } from 'react-i18next'
import s from './component.module.scss'

const LoginComponent: React.FC = (): React.ReactElement => {
	const { t } = useTranslation(['auth', 'common'])

	return (
		<>
			<FormTitle>{t('signIn.title')}</FormTitle>

			<FormTabs />

			<SignInForm />

		</>
	)
}
export default LoginComponent
