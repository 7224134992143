export const SuccessIcon: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle
                cx='10'
                cy='10'
                r='9'
                stroke='#80FF77'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M6 14H14'
                stroke='#80FF77'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M13.5 6.224L9.124 10.6L6.5 7.974'
                stroke='#80FF77'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    )
}
export const ErrorIcon: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='26'
            height='26'
            viewBox='0 0 26 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle
                cx='13'
                cy='13.002'
                r='6.50271'
                stroke='#FF3030'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M5.85997 5.99709C2.04375 9.88583 2.04375 16.1142 5.85997 20.0029'
                stroke='#FF3030'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M20.14 5.99709C23.9562 9.88583 23.9562 16.1142 20.14 20.0029'
                stroke='#FF3030'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M11.4994 11.4994L14.5006 14.5006'
                stroke='#FF3030'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M14.5006 11.4994L11.4994 14.5006'
                stroke='#FF3030'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    )
}
export const WarningIcon: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='26'
            height='26'
            viewBox='0 0 26 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M18.002 16.8166V14.9808'
                stroke='#F7D41A'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M18.0026 18.9025C17.9753 18.9025 17.9531 18.9246 17.9531 18.9519V18.9525C17.9531 18.9801 17.9755 19.0025 18.0031 19.0025C18.0308 19.0025 18.0532 18.9801 18.0532 18.9525C18.0532 18.9249 18.0308 18.9025 18.0031 18.9025H18.0026'
                stroke='#F7D41A'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M18.0021 22.0037C15.2395 22.0037 13 19.7642 13 17.0017C13 14.2391 15.2395 11.9996 18.0021 11.9996C20.7647 11.9996 23.0042 14.2391 23.0042 17.0017C23.0042 18.3283 22.4772 19.6006 21.5391 20.5387C20.601 21.4767 19.3287 22.0037 18.0021 22.0037'
                stroke='#F7D41A'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M8.17486 8.8216C8.2726 8.91934 8.2726 9.0778 8.17486 9.17554C8.07712 9.27327 7.91866 9.27327 7.82092 9.17554C7.72318 9.0778 7.72318 8.91934 7.82092 8.8216C7.91875 8.72409 8.07703 8.72409 8.17486 8.8216'
                stroke='#F7D41A'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M13.1769 8.8216C13.2747 8.91934 13.2747 9.0778 13.1769 9.17554C13.0792 9.27327 12.9207 9.27327 12.823 9.17554C12.7253 9.0778 12.7253 8.91934 12.823 8.8216C12.9208 8.72409 13.0791 8.72409 13.1769 8.8216'
                stroke='#F7D41A'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M18.179 8.8216C18.2767 8.91934 18.2767 9.0778 18.179 9.17554C18.0813 9.27327 17.9228 9.27327 17.8251 9.17554C17.7273 9.0778 17.7273 8.91934 17.8251 8.8216C17.9229 8.72409 18.0812 8.72409 18.179 8.8216'
                stroke='#F7D41A'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M9.99872 14.0004H5.99705C5.46635 14.0006 4.95735 13.7898 4.58209 13.4145C4.20682 13.0393 3.99622 12.5303 3.99622 11.9996V5.99708C3.99622 5.46638 4.20682 4.95738 4.58209 4.58212C4.95735 4.20686 5.46635 3.9961 5.99705 3.99625H20.0029C20.5336 3.9961 21.0426 4.20686 21.4178 4.58212C21.7931 4.95738 22.0037 5.46638 22.0037 5.99708V8.99833'
                stroke='#F7D41A'
                stroke-width='1.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    )
}
