import * as Yup from 'yup'

const validationSchema = () =>
    Yup.object().shape({
        password: Yup.string().min(5).max(255).required(),

        confirm_password: Yup.string()
            .min(5)
            .max(255)
            .required()
            .oneOf([Yup.ref('password'), null])
    })

export default validationSchema
