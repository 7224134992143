import { FormikProps, useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import getSoftnoteImportErrorDesc from 'utils/getSoftnoteImportErrorDesc'
//import SoftNoteService from 'services/SoftNote/SoftNoteService'
import Button from 'com/shared/buttons/Button'
import Field from 'com/shared/form/Field'
import styles from './styles.module.scss'
import { Values } from './types'
import validationSchema from './validationSchema'
import BanknotesService from "services/Banknotes/BanknotesService";

interface Props {
    onClose: () => void
}

const LightModeForm: React.FC<Props> = props => {
    const { onClose } = props
    const { t: lang } = useTranslation('common')

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: {
            serialNumber: '',
            passcode: ''
        } as Values,
        validationSchema: validationSchema,
        validateOnChange: false,

        onSubmit: async (values: Values) => {
            try {
                const value = [
                    {
                        [values.serialNumber]: Number(values.passcode)
                    }
                ]

                const response = await BanknotesService.import({ stack: value })
                const { replenished, rejected } = response.data.payload

                if (rejected && rejected.length >= 1) {
                    rejected.forEach(item =>
                        toast.error(
                            `${item.serial}. ${lang(
                                'toasts.errors.error'
                            )}: ${getSoftnoteImportErrorDesc(item.error)}`
                        )
                    )
                }

                if (replenished && replenished.length >= 1) {
                    replenished.forEach(item =>
                        toast.success(
                            `${lang('toasts.success.successImport')}: ${
                                item.amount
                            }${item.currency.ticker}`
                        )
                    )
                }

                formik.resetForm()
                onClose()
            } catch (error) {
                console.log(error)
                //toast.error(lang('toasts.errors.invalidRequest'))
            }
        }
    })

    useEffect(() => {
        if (formik.errors) {
            for (const error of Object.values(formik.errors)) {
                toast.error(error)
            }
        }
    }, [formik.errors])

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <Field
                    type='text'
                    name='serialNumber'
                    value={formik.values.serialNumber}
                    onChange={formik.handleChange}
                    label={lang('labels.enterSerialNumber(s)')}
                />

                <Field
                    type='text'
                    name='passcode'
                    value={formik.values.passcode}
                    onChange={formik.handleChange}
                    label={lang('labels.enterPasscode')}
                />
            </div>
            <div className={styles.actions}>
                <Button onClick={onClose} size='large'>
                    {lang('buttons.cancel')}
                </Button>
                <Button
                    size='large'
                    type='primary'
                    onClick={formik.handleSubmit}
                >
                    {lang('buttons.import')}
                </Button>
            </div>
        </div>
    )
}

export default LightModeForm
