import { observer } from 'mobx-react-lite'
import AuthStore from 'store/AuthStore'
import DashboardPageComponent from './component'

const DashboardPage: React.FC = (): React.ReactElement => {
	if (!AuthStore.user) {
		return <></>
	}

	return <DashboardPageComponent user={AuthStore.user} />
}
export default observer(DashboardPage)
