const IconClose: React.FC = (): React.ReactElement => {
	return (
		<svg
			width='17'
			height='17'
			viewBox='0 0 17 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M10.8721 8.49886L16.6054 2.77886C16.8565 2.52779 16.9976 2.18727 16.9976 1.8322C16.9976 1.47713 16.8565 1.1366 16.6054 0.88553C16.3544 0.634459 16.0138 0.493408 15.6588 0.493408C15.3037 0.493408 14.9632 0.634459 14.7121 0.88553L8.99211 6.61886L3.27211 0.88553C3.02103 0.634459 2.68051 0.493408 2.32544 0.493408C1.97037 0.493408 1.62984 0.634459 1.37877 0.88553C1.1277 1.1366 0.98665 1.47713 0.98665 1.8322C0.98665 2.18727 1.1277 2.52779 1.37877 2.77886L7.11211 8.49886L1.37877 14.2189C1.2538 14.3428 1.15461 14.4903 1.08692 14.6528C1.01923 14.8152 0.984375 14.9895 0.984375 15.1655C0.984375 15.3415 1.01923 15.5158 1.08692 15.6783C1.15461 15.8408 1.2538 15.9882 1.37877 16.1122C1.50272 16.2372 1.65019 16.3364 1.81267 16.4041C1.97515 16.4717 2.14942 16.5066 2.32544 16.5066C2.50146 16.5066 2.67573 16.4717 2.83821 16.4041C3.00069 16.3364 3.14816 16.2372 3.27211 16.1122L8.99211 10.3789L14.7121 16.1122C14.8361 16.2372 14.9835 16.3364 15.146 16.4041C15.3085 16.4717 15.4828 16.5066 15.6588 16.5066C15.8348 16.5066 16.0091 16.4717 16.1715 16.4041C16.334 16.3364 16.4815 16.2372 16.6054 16.1122C16.7304 15.9882 16.8296 15.8408 16.8973 15.6783C16.965 15.5158 16.9998 15.3415 16.9998 15.1655C16.9998 14.9895 16.965 14.8152 16.8973 14.6528C16.8296 14.4903 16.7304 14.3428 16.6054 14.2189L10.8721 8.49886Z'
				fill='#2261F6'
			/>
		</svg>
	)
}
export default IconClose
