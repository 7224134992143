import {FormikProps, useFormik} from 'formik'
import {useCallback, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {PreRegistrationArgs} from 'services/Auth/AuthService.types'
import SignUpStore from 'store/SignUpStore'
import PreRegistrationComponent from './component'
import {Values} from './types'
import validationSchema from './validationSchema'
import {SignUpSteps} from "../../../../models/SignUpSteps";

const PreRegistration: React.FC<WithTranslation> = ({
                                                        t
                                                    }): React.ReactElement => {
    const [isLoading, setLoading] = useState<boolean>(false)
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false)
    const [errorsValidate, setErrors] = useState<string[]>([])

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: {} as Values,
        validationSchema: validationSchema(),
        validateOnChange: validateOnChange,

        onSubmit: async (values: Values) => {
            setLoading(true)

            let args: PreRegistrationArgs = {
                login: values.login,
                password: values.password,
                password_repeat: values.password_repeat,
                token: values.token
            }

            const response = await SignUpStore.preRegistration(args)
                .then(res => {

                    let tt = res.data

                    if (tt.hasError) {
                        setErrors(tt.errorMsgs)
                    }


                    SignUpStore.setLogin(values.login)
                    SignUpStore.setPwd(values.password)
                    SignUpStore.setSeedPhrase(tt.seedPhrase)
 
                    SignUpStore.setStep(SignUpSteps.signUpApprove)


                }).catch(error => {
                    setErrors(error.response.data.errorMsgs)
                })
            
            setLoading(false)
        }
    })

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()
        setRefreshReCaptcha(r => !r)
    }

    const onChangeCaptcha = useCallback(
        (value: string | null) => {
            formik.setFieldValue('token', value)
        },
        [refreshReCaptcha]
    )

    return (
        <PreRegistrationComponent
            formik={formik}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            t={t}
            refreshReCaptcha={refreshReCaptcha}
            onChangeCaptcha={onChangeCaptcha}
            errors={errorsValidate}
        />
    )
}
export default withTranslation(['auth', 'common'])(PreRegistration)
