import {GoogleReCaptcha} from 'react-google-recaptcha-v3'
import {TFunction} from 'react-i18next'
import Loader from 'com/UI/Loader'
import FormTabs from 'com/UI/form/FormTabs'
import FormTitle from 'com/UI/form/FormTitle'
import s from './component.module.scss'
import TextArea from "../../../../com/shared/form/TextArea";

interface Props {
    isLoading: boolean
    handleSubmit: () => void
    handleClickBack: () => void
    t: TFunction
    refreshReCaptcha: boolean
    onChangeCaptcha: (value: string | null) => void
    errors: string[]
    seedPhrase: string
}

const SignUpApproveComponent: React.FC<Props> = (
    props: Props
): React.ReactElement => {
    const {
        isLoading,
        handleSubmit,
        handleClickBack,
        t,
        refreshReCaptcha,
        onChangeCaptcha,
        errors,
        seedPhrase
    } = props

    return (
        <>
            <FormTitle>{t('preRegister.seedPhraseTitle')}</FormTitle>

            <FormTabs/>

            <div className={s.form}>
                {errors.length > 0 ? (
                    <div className={s.errorMessage}>
                        {errors.map((item, index) => {
                                if (index === 0) {
                                    return <>{item}</>
                                }
                                return <><br/><br/>{item}</>
                            }
                        )
                        }
                    </div>
                ) : (<></>)
                }
                <TextArea
                    name='seedPhrase'
                    value={seedPhrase}
                    placeholder={t('preRegister.fields.seedPhrase.placeholder')}
                    label={t('preRegister.fields.seedPhrase.label')}
                    readOnly={true}
                />

                <div className={s.actions}>
                    <button className={s.backButton} onClick={handleClickBack}>
                        {t('buttons.back', {ns: 'common'})}
                    </button>

                    <button className={s.submitButton} onClick={handleSubmit} type={"button"}>
                        {isLoading ? (
                            <Loader size='small'/>
                        ) : (
                            t('buttons.next', {ns: 'common'})
                        )}
                    </button>
                </div>
                <GoogleReCaptcha
                    refreshReCaptcha={refreshReCaptcha}
                    onVerify={onChangeCaptcha}
                />
            </div>
        </>
    )
}
export default SignUpApproveComponent
