import { animated, useSpring } from '@react-spring/web'
import cls from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import { IconTriangle } from 'com/Icons'
import ProductsMenuList from 'com/UI/Menu/ProductsMenuList'
import s from './index.module.scss'

const ProductsMenu: React.FC = (): React.ReactElement => {
	const [isOpen, setOpen] = useState<boolean>(false)
	const { t } = useTranslation('common')

	const styles = useSpring({
		maxHeight: isOpen ? `500px` : '0px',
		config: { duration: 300 }
	})

	const handleClick = (): void => {
		setOpen(prev => !prev)
	}

	return (
		<div className={s.nav}>
			<button
				className={cls(s.nav__label, isOpen ? s.labelActive : '')}
				onClick={handleClick}
			>
				{t('menu.products')}

				<IconTriangle />
			</button>
			<animated.div style={styles} className={s.nav__list}>
				<ProductsMenuList />
			</animated.div>
		</div>
	)
}
export default ProductsMenu
