import {makeAutoObservable} from 'mobx'
import {LSKeyRegKey, LSKeyRegLogin} from 'constants/LocalStorageConstants'
import AuthService from 'services/Auth/AuthService'
import {CheckCodeResponse, PreRegistrationArgs} from 'services/Auth/AuthService.types'
import LocalStorageService from 'services/LocalStorageService'
import {ResponseValidate} from 'models/ResponseValidate'
import {SignUpSteps} from 'models/SignUpSteps'
import {AxiosResponse} from "axios";

class SignUpStore {
    step: SignUpSteps = SignUpSteps.preRegistration
    login: string = ''
    pwd: string = ''
    seedPhrase: string = ''

    constructor() {
        makeAutoObservable(this)
    }

    setStep(step: SignUpSteps): void {
        this.step = step
    }

    setLogin(login: string): void {
        this.login = login
    }
    getLogin(): string{
        return this.login
    }

    setPwd(pwd: string): void {
        this.pwd = pwd
    }
    getPwd(): string{
        return this.pwd
    }

    setSeedPhrase(seedPhrase: string): void {
        this.seedPhrase = seedPhrase
    }
    getSeedPhrase(): string{
        return this.seedPhrase
    }

    getStep(): SignUpSteps {
        return this.step
    }

    async preRegistration(args: PreRegistrationArgs): Promise<AxiosResponse<ResponseValidate>> {
        return await AuthService.preRegistration(args)
    }

    setRegistrationStep(regKey: string) {
        LocalStorageService.remove(LSKeyRegLogin)
        LocalStorageService.set(LSKeyRegKey, regKey)
        this.setStep(SignUpSteps.registration)
    }
}

export default new SignUpStore()
