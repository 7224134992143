import {FormikProps, useFormik} from 'formik'
import {observer} from 'mobx-react-lite'
import {ChangeEvent, useCallback, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {AppRoutes} from 'routes/AppRoutes'
import {ModalsName} from 'models/Modals'
import AuthStore from 'store/AuthStore'
import ModalsStore from 'store/ModalsStore'
import SignInFormComponent from './component'
import {Values} from './types'
import validationSchema from './validationSchema'
import AuthService from "../../../../services/Auth/AuthService";
import SignUpStore from "../../../../store/SignUpStore";
import {SignUpSteps} from "../../../../models/SignUpSteps";

const SignInForm: React.FC<WithTranslation> = ({t}): React.ReactElement => {
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)
    const [isShowPassword, setShowPassword] = useState<boolean>(false)
    const [errorsValidate, setErrors] = useState<string[]>([])

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: {} as Values,
        validationSchema: validationSchema(),
        validateOnChange: validateOnChange,

        onSubmit: async (values: Values) => {
            setLoading(true)

            await AuthService.login({
                login: values.login,
                password: values.password,
                token: values.token
            }).then(
                res => {
                    if (res.data.hasError) {
                        setErrors(res.data.errorMsgs)
                        return
                    }

                    if (!AuthStore.checkAccessResponse(res.data)) {
                        setErrors(res.data.errorMsgs)
                        return
                    }

                    // redirect to inside
					setLoading(false)
                }
            )
                .catch(error => {
                    console.log(error.response.data.errorMsgs)
                    setErrors(error.response.data.errorMsgs)
                });

            setLoading(false)
        }
    })

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()
        setRefreshReCaptcha(r => !r)
    }

    const handleClickShowPassword = () => {
        setShowPassword(prev => !prev)
    }

    const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.trim()
        formik.setFieldValue('login', value)
    }
    const onChangeCaptcha = useCallback(
        (value: string | null) => {
            formik.setFieldValue('token', value)
        },
        [refreshReCaptcha]
    )

    return (
        <SignInFormComponent
            onChangeCaptcha={onChangeCaptcha}
            formik={formik}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            isShowPassword={isShowPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleChangeEmail={handleChangeEmail}
            t={t}
            refreshReCaptcha={refreshReCaptcha}
            errors={errorsValidate}
        />
    )
}
export default withTranslation(['auth', 'common'])(observer(SignInForm))
