import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import { ModalsName } from 'models/Modals'
import ModalsStore from 'store/ModalsStore'
import s from './index.module.scss'

const ForgotPasswordButton: React.FC = (): React.ReactElement => {
	const { t } = useTranslation('common')

	const handleClick = () => {
		ModalsStore.set(ModalsName.mobileMenu, false)
	}

	return (
		<NavLink
			to={AppRoutes.auth.passwordReset}
			className={s.button}
			onClick={handleClick}
		>
			{t('buttons.forgotPassword')}
		</NavLink>
	)
}
export default ForgotPasswordButton
