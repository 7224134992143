import s from './index.module.scss'

interface Props {
	name: string
	type?: string
	value: string | number
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	error?: string | undefined
	placeholder?: string
	label?: string
	readOnly?: boolean
}

const Field: React.FC<Props> = (props: Props): React.ReactElement => {
	const { name, type, value, onChange, error, placeholder, label, readOnly } =
		props

	return (
		<div className={s.wrapper}>
			{label && <label>{label}</label>}

			<input
				type={type ? type : 'text'}
				name={name}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				readOnly={readOnly}
				className={s.input}
			/>

			{error && <span className={s.error}>{error}</span>}
		</div>
	)
}
export default Field
