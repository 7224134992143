import classnames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import IconXCircle from 'assets/icons/x-circle.svg'
import ModalWrapper from 'com/UI/ModalWrapper'
import LightModeForm from './LightModeForm'
import ProModeForm from './ProModeForm'
import styles from './styles.module.scss'

interface Props {
    isOpen: boolean
    onClose: () => void
}

const ImportModal: React.FC<Props> = props => {
    const { isOpen, onClose } = props
    const [activeTab, setActiveTab] = useState<string>('Light Mode')
    const { t: lang } = useTranslation('common')
    const { t: langImport } = useTranslation('import-modal')

    return (
        <ModalWrapper open={isOpen} onClose={onClose}>
            <div className={styles.modal}>
                <div className={styles.modalTitle}>{langImport('title')}</div>

                <button className={styles.modalButtonClose} onClick={onClose}>
                    <img src={IconXCircle} alt='close' />
                </button>
                <button className={styles.modalButtonClose} onClick={onClose}>
                    <img src={IconXCircle} alt='close' />
                </button>

                <p className={styles.modalSubtitle}>{langImport('subtitle')}</p>

                <div className={styles.tabs}>
                    <button
                        onClick={() => setActiveTab('Light Mode')}
                        className={classnames(
                            styles.btn,
                            activeTab === 'Light Mode' && styles.active
                        )}
                    >
                        {lang('buttons.lightMode')}
                    </button>
                    <button
                        onClick={() => setActiveTab('Pro Mode')}
                        className={classnames(
                            styles.btn,
                            activeTab === 'Pro Mode' && styles.active
                        )}
                    >
                        {lang('buttons.proMode')}
                    </button>
                </div>

                {activeTab === 'Light Mode' ? (
                    <LightModeForm onClose={onClose} />
                ) : (
                    <ProModeForm onClose={onClose} />
                )}
            </div>
        </ModalWrapper>
    )
}

export default ImportModal
