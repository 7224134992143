import { BanknotesImportError } from 'store/Banknotes/types'

function getSoftnoteImportErrorDesc(error: BanknotesImportError) {
    let text = ''

    switch (error) {
        case BanknotesImportError.ALREADY:
            text = 'softnote already imported.'
            break

        case BanknotesImportError.INVALID:
            text = 'invalid value.'
            break

        case BanknotesImportError.BLOCKED:
            text = 'blocked.'
            break

        default:
            text = 'something wrong.'
            break
    }

    return text
}

export default getSoftnoteImportErrorDesc
