import {FormikProps, useFormik} from 'formik'
import React, {useCallback, useEffect, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {LSKeyRegLogin, LSKeyRegKey, LSLoginValue, LSUserPwd, LSUserSeedPhrase} from 'constants/LocalStorageConstants'
import AuthService from 'services/Auth/AuthService'
import LocalStorageService from 'services/LocalStorageService'
import {AppRoutes} from 'routes/AppRoutes'
import {ResponseStatuses} from 'models/ResponseValidate'
import {SignUpSteps} from 'models/SignUpSteps'
import SignUpStore from 'store/SignUpStore'
import SignUpApproveComponent from './component'
import {Values} from './types'
import validationSchema from './validationSchema'

const SignUpApprove: React.FC<WithTranslation> = ({t}): React.ReactElement => {
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false)
    const [errorsValidate, setErrors] = useState<string[]>([])

    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: {} as Values,
        validationSchema: validationSchema(),
        validateOnChange: validateOnChange,

        onSubmit: async (values: Values) => {
            setLoading(true)

            const login = SignUpStore.getLogin()
            const pwd = SignUpStore.getPwd()
            const seedPhrase = SignUpStore.getSeedPhrase()

            console.log(login, pwd, seedPhrase)

            await AuthService.signUpApprove({
                login: login,
                password: pwd,
                seedPhrase: seedPhrase,
                token: values.token
            })
                .then(res => {
                    setLoading(false)

                    if (res.data.hasError) {
                        setErrors(res.data.errorMsgs)
                    }

                    SignUpStore.setStep(SignUpSteps.registration)
                })

                .catch(error => {
                    setErrors(error.response.data.errorMsgs)
                })
        }
    })

    const handleClickBack = () => {
        SignUpStore.setStep(SignUpSteps.preRegistration)
    }

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()
    }

    const onChangeCaptcha = useCallback(
        (value: string | null) => {
            formik.setFieldValue('token', value)
        },
        [refreshReCaptcha]
    )

    const seedPhrase = SignUpStore.getSeedPhrase()

    return (
        <SignUpApproveComponent
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            handleClickBack={handleClickBack}
            t={t}
            refreshReCaptcha={refreshReCaptcha}
            onChangeCaptcha={onChangeCaptcha}
            errors={errorsValidate}
            seedPhrase={seedPhrase}
        />
    )
}
export default withTranslation(['auth', 'common'])(SignUpApprove)
