export interface TectumCloudCurrency {
    id: number
    network: string
    protocol: string
    ticker: string
    key: string
}

export interface Address {
    network: string
    protocol: string
    ticker: string
    currency: number
    address: string
}

export enum BanknotesImportError {
    INVALID,
    BLOCKED,
    ALREADY
}

