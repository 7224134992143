const LocalStorageService = {
    set: function (key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value))
    },

    get: function (key: string): any {
        const value = localStorage.getItem(key)

        if (!value || value === 'undefined') return null

        return JSON.parse(value)
    },

    remove: function (key: string): void {
        localStorage.removeItem(key)
    },
}

export default LocalStorageService
