import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import IconLogo from 'assets/icons/logo.svg'
import { AppRoutes } from 'routes/AppRoutes'
import PrivacyPolicySign from 'com/UI/PrivacyPolicySign'
import s from './component.module.scss'

interface Props {
}

const Page404Component: React.FC<Props> = (
	props: Props
): React.ReactElement => {
	const { t } = useTranslation('404')

	return (
		<section className={s.wrapper}>
			<div className={s.container}>
				<Link to={AppRoutes.home} className={s.logo}>
					<img src={IconLogo} alt='Tectum logo' />
				</Link>

				<h1 className={s.title}>
					404 <br />
					{t('title')}
				</h1>
				<p className={s.text}>{t('text')}</p>
				<Link to={AppRoutes.home} className={s.button}>
					{t('button')}
				</Link>

				<PrivacyPolicySign />
			</div>
		</section>
	)
}
export default Page404Component
