import s from './index.module.scss'

interface Props {
	children: string
}

const FormTitle: React.FC<Props> = (props: Props): React.ReactElement => {
	const { children } = props
	return <h1 className={s.title}>{children}</h1>
}
export default FormTitle
