const IconLogout: React.FC = (): React.ReactElement => {
	return (
		<svg
			width='25'
			height='28'
			viewBox='0 0 25 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.00004 15.858C4.00004 16.3097 3.66404 16.6764 3.25012 16.6764C2.8362 16.6764 2.50019 16.3097 2.50019 15.858V7.76516C2.50019 5.16566 4.43831 3.05078 6.82151 3.05078H11.5675C13.9555 3.05078 15.8985 5.17098 15.8985 7.77685V8.76627C15.8985 9.21688 15.5625 9.58353 15.1486 9.58353C14.7347 9.58353 14.3987 9.21688 14.3987 8.76627V7.77685C14.3987 6.07219 13.1287 4.68742 11.5675 4.68742H6.82151C5.26517 4.68742 4.00004 6.069 4.00004 7.76516V15.858ZM14.3985 18.5906C14.3985 18.1389 14.7345 17.7723 15.1484 17.7723C15.5623 17.7723 15.8983 18.1389 15.8983 18.5906V19.5906C15.8983 22.1901 13.9602 24.305 11.577 24.305H6.83008C4.44298 24.305 2.5 22.1848 2.5 19.579C2.5 19.1273 2.836 18.7617 3.24992 18.7617C3.66384 18.7617 3.99985 19.1273 3.99985 19.579C3.99985 21.2836 5.26985 22.6684 6.83008 22.6684H11.577C13.1333 22.6684 14.3985 21.2879 14.3985 19.5906V18.5906ZM22.4433 13.3657C22.3274 13.0585 22.0537 12.8587 21.7499 12.8587H10.0491C9.63518 12.8587 9.2982 13.2264 9.2982 13.6781C9.2982 14.1298 9.63518 14.4964 10.0491 14.4964H19.9345L18.3752 16.1905C18.0811 16.5093 18.0811 17.0269 18.3732 17.3478C18.5193 17.5083 18.7122 17.589 18.904 17.589C19.0959 17.589 19.2868 17.5083 19.4339 17.3499L22.2797 14.2584C22.4939 14.0246 22.5592 13.6717 22.4433 13.3657ZM19.6218 12.1797C19.4309 12.1797 19.24 12.1 19.094 11.9427L18.3762 11.168C18.082 10.8502 18.0801 10.3316 18.3713 10.0117C18.6635 9.68864 19.1368 9.68651 19.4319 10.0053L20.1487 10.779C20.4438 11.0979 20.4458 11.6154 20.1546 11.9364C20.0075 12.0979 19.8147 12.1797 19.6218 12.1797Z'
				fill='#343C4D'
			/>
		</svg>
	)
}
export default IconLogout
