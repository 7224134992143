export const IconBills: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='37'
            height='36'
            viewBox='0 0 37 36'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M21.0425 3H9.04248C8.24683 3 7.48377 3.31607 6.92116 3.87868C6.35855 4.44129 6.04248 5.20435 6.04248 6V30C6.04248 30.7956 6.35855 31.5587 6.92116 32.1213C7.48377 32.6839 8.24683 33 9.04248 33H27.0425C27.8381 33 28.6012 32.6839 29.1638 32.1213C29.7264 31.5587 30.0425 30.7956 30.0425 30V12L21.0425 3Z'
                stroke='#D5D5D5'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M21.0425 3V12H30.0425'
                stroke='#D5D5D5'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M24.0425 19.5H12.0425'
                stroke='#D5D5D5'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M24.0425 25.5H12.0425'
                stroke='#D5D5D5'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15.0425 13.5H13.5425H12.0425'
                stroke='#D5D5D5'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconExchange: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='37'
            height='36'
            viewBox='0 0 37 36'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M27.0425 30V15'
                stroke='#D5D5D5'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M18.0425 30V6'
                stroke='#D5D5D5'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.04248 30V21'
                stroke='#D5D5D5'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconHistory: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='30'
            height='30'
            viewBox='0 0 30 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M14.8 2.7998C8.64221 2.7998 4 5.06232 4 8.06127C4 11.0603 8.64226 13.3228 14.8 13.3228C20.9577 13.3228 25.6 11.0603 25.6 8.06127C25.6 5.06232 20.9578 2.7998 14.8 2.7998Z'
                fill='white'
            />
            <path
                d='M14.8 14.9845C10.4069 14.9845 6.81677 14.0009 4.6371 12.3741C4.38464 12.1857 4 12.3578 4 12.6728V14.5362C4 17.3773 8.64226 19.5208 14.8 19.5208C20.9577 19.5208 25.6 17.3773 25.6 14.5362V12.6729C25.6 12.3578 25.2154 12.1857 24.9629 12.3741C22.7832 14.001 19.1931 14.9845 14.8 14.9845Z'
                fill='white'
            />
            <path
                d='M14.8 21.3136C10.4069 21.3136 6.81677 20.33 4.6371 18.7032C4.38464 18.5148 4 18.6869 4 19.0019V20.8653C4 23.7065 8.64221 25.8499 14.8 25.8499C20.9578 25.8499 25.6 23.7065 25.6 20.8653V19.002C25.6 18.6869 25.2154 18.5148 24.9629 18.7032C22.7832 20.33 19.1931 21.3136 14.8 21.3136Z'
                fill='white'
            />
        </svg>
    )
}

export const IconWallet: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='37'
            height='36'
            viewBox='0 0 37 36'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M30.4175 10.125H7.91748C7.61911 10.125 7.33296 10.0065 7.12199 9.79549C6.91101 9.58452 6.79248 9.29837 6.79248 9C6.79248 8.70163 6.91101 8.41548 7.12199 8.20451C7.33296 7.99353 7.61911 7.875 7.91748 7.875H27.0425C27.3408 7.875 27.627 7.75647 27.838 7.54549C28.049 7.33452 28.1675 7.04837 28.1675 6.75C28.1675 6.45163 28.049 6.16548 27.838 5.95451C27.627 5.74353 27.3408 5.625 27.0425 5.625H7.91748C7.02351 5.6287 6.16722 5.98547 5.53509 6.6176C4.90295 7.24974 4.54618 8.10603 4.54248 9V27C4.54618 27.894 4.90295 28.7503 5.53509 29.3824C6.16722 30.0145 7.02351 30.3713 7.91748 30.375H30.4175C31.0142 30.375 31.5865 30.1379 32.0085 29.716C32.4304 29.294 32.6675 28.7217 32.6675 28.125V12.375C32.6675 11.7783 32.4304 11.206 32.0085 10.784C31.5865 10.3621 31.0142 10.125 30.4175 10.125ZM25.355 21.9375C25.0212 21.9375 24.695 21.8385 24.4175 21.6531C24.1399 21.4677 23.9237 21.2041 23.7959 20.8958C23.6682 20.5874 23.6348 20.2481 23.6999 19.9208C23.765 19.5934 23.9257 19.2928 24.1617 19.0568C24.3977 18.8208 24.6984 18.66 25.0258 18.5949C25.3531 18.5298 25.6924 18.5632 26.0008 18.691C26.3091 18.8187 26.5727 19.035 26.7581 19.3125C26.9435 19.59 27.0425 19.9162 27.0425 20.25C27.0425 20.6976 26.8647 21.1268 26.5482 21.4432C26.2318 21.7597 25.8025 21.9375 25.355 21.9375Z' />
        </svg>
    )
}

export const IconImport: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='30'
            height='30'
            viewBox='0 0 30 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6.3998 20.8H5.1998C4.56329 20.8 3.95284 20.5471 3.50275 20.0971C3.05266 19.647 2.7998 19.0365 2.7998 18.4V6.4C2.7998 5.76348 3.05266 5.15303 3.50275 4.70294C3.95284 4.25286 4.56329 4 5.1998 4H24.3998C25.0363 4 25.6468 4.25286 26.0969 4.70294C26.5469 5.15303 26.7998 5.76348 26.7998 6.4V18.4C26.7998 19.0365 26.5469 19.647 26.0969 20.0971C25.6468 20.5471 25.0363 20.8 24.3998 20.8H23.1998'
                stroke='white'
                strokeWidth='2.4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.7998 18.3999L20.7998 25.5999H8.7998L14.7998 18.3999Z'
                stroke='white'
                strokeWidth='2.4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconBurn: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='29'
            height='29'
            viewBox='0 0 29 29'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.72923 9.33945L11.8476 4.53467L16.2877 9.7148L19.1607 6.36292L21.4509 9.03477C22.9224 10.7516 23.7313 12.9383 23.7313 15.1995V15.5042C23.7313 17.9286 22.7683 20.2538 21.0539 21.9681C19.3396 23.6824 17.0145 24.6455 14.59 24.6455V24.6455C9.54143 24.6455 5.44873 20.5528 5.44873 15.5042V15.5042C5.44873 13.243 6.25763 11.0563 7.72923 9.33945Z'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconRemint: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='30'
            height='30'
            viewBox='0 0 30 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M24.8127 11.7924C23.7687 12.3948 23.1891 13.5264 23.1999 14.7312C23.1999 14.754 23.1999 14.7768 23.1999 14.7996C23.1999 14.8224 23.1999 14.8464 23.1999 14.8692C23.1891 16.074 23.7687 17.2056 24.8127 17.808L24.8223 17.814C25.5315 18.2232 26.4543 17.8188 26.6043 17.0136C26.8791 15.5352 26.8659 14.034 26.5911 12.5856C26.4387 11.7828 25.5195 11.3844 24.8127 11.7924V11.7924V11.7924Z'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.7752 7.43176C10.666 7.49176 10.558 7.55416 10.4512 7.61896C9.45397 8.22616 8.19997 8.21536 7.18837 7.63096V7.63096C6.48517 7.22536 6.36157 6.23416 6.97597 5.70376C8.09797 4.73416 9.39997 3.96736 10.828 3.46576C11.596 3.19576 12.3976 3.79816 12.3976 4.61176V4.62136C12.3976 5.78176 11.7916 6.87376 10.7752 7.43176V7.43176Z'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.8332 22.1628C18.94 22.104 19.0456 22.0428 19.15 21.9792C20.1472 21.372 21.4012 21.3828 22.4128 21.9672V21.9672C23.116 22.3728 23.2396 23.364 22.6252 23.8944C21.5032 24.864 20.2012 25.6308 18.7732 26.1336C18.0052 26.4048 17.2024 25.8024 17.2024 24.9876V24.9876C17.2024 23.8224 17.812 22.7256 18.8332 22.1628V22.1628Z'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.78712 17.8068V17.8068C4.08032 18.2148 3.16112 17.8164 3.00872 17.0148C2.73392 15.5664 2.72072 14.064 2.99552 12.5856C3.14552 11.7804 4.06712 11.376 4.77632 11.7852L4.78712 11.7912C5.83112 12.3936 6.41072 13.5252 6.39992 14.73C6.39992 14.7528 6.39992 14.7768 6.39992 14.7996C6.39992 14.8224 6.39992 14.8452 6.39992 14.868C6.41072 16.0728 5.83112 17.2044 4.78712 17.8068V17.8068Z'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17.2024 4.62247V4.62247C17.2024 3.80647 18.0076 3.21007 18.7768 3.47887C20.1688 3.96487 21.4768 4.70407 22.6204 5.68327C23.242 6.21607 23.1316 7.21567 22.4224 7.62487L22.4116 7.63087C21.4 8.21407 20.1472 8.22607 19.15 7.61887C19.0432 7.55407 18.9352 7.49047 18.8248 7.43047C17.8084 6.87367 17.2024 5.78167 17.2024 4.62247H17.2024Z'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.3975 24.9768V24.9768C12.3975 25.7928 11.5923 26.3892 10.8231 26.1204C9.43108 25.6344 8.12308 24.8952 6.97948 23.916C6.35788 23.3832 6.46828 22.3836 7.17748 21.9744V21.9744C8.19508 21.3864 9.45628 21.3768 10.4619 21.9864C10.5651 22.0488 10.6695 22.11 10.7763 22.1676C11.7915 22.7256 12.3975 23.8176 12.3975 24.9768V24.9768Z'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.7998 11.2002L18.4001 14.8005L14.7998 18.4007L11.1995 14.8005L14.7998 11.2002Z'
                stroke='white'
                strokeWidth='1.49999'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconBuy: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='30'
            height='30'
            viewBox='0 0 30 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M25.6046 22.0029L22.0031 25.6044'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M25.6046 25.6044V22.0029H22.0031'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M3.99548 7.59711L7.59698 3.99561'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M3.99548 3.99561V7.59711H7.59698'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.7988 24.4042C15.4454 24.4042 18.4015 21.4481 18.4015 17.8015C18.4015 14.1549 15.4454 11.1987 11.7988 11.1987C8.1522 11.1987 5.19604 14.1549 5.19604 17.8015C5.19604 21.4481 8.1522 24.4042 11.7988 24.4042Z'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M22.4702 16.4674C25.0487 13.8889 25.0487 9.70824 22.4702 7.1297C19.8916 4.55117 15.711 4.55117 13.1324 7.1297'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.6473 15.1518L14.4483 16.9523C14.9172 17.421 14.9173 18.1811 14.4486 18.65L12.648 20.451C12.1793 20.9199 11.4192 20.92 10.9503 20.4513L9.14933 18.6507C8.68046 18.182 8.68035 17.4219 9.14909 16.953L10.9496 15.152C11.4183 14.6832 12.1784 14.683 12.6473 15.1518Z'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconRequestToPay: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='29'
            height='30'
            viewBox='0 0 29 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M16.9 2.7998H7.30002C6.6635 2.7998 6.05306 3.05266 5.60297 3.50275C5.15288 3.95284 4.90002 4.56329 4.90002 5.1998V24.3998C4.90002 25.0363 5.15288 25.6468 5.60297 26.0969C6.05306 26.5469 6.6635 26.7998 7.30002 26.7998H21.7C22.3365 26.7998 22.947 26.5469 23.3971 26.0969C23.8472 25.6468 24.1 25.0363 24.1 24.3998V9.9998L16.9 2.7998Z'
                stroke='white'
                strokeWidth='2.4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M16.9 2.7998V9.9998H24.1'
                stroke='white'
                strokeWidth='2.4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.5 21.9998V14.7998'
                stroke='white'
                strokeWidth='2.4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.9 18.3999H18.1'
                stroke='white'
                strokeWidth='2.4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconPurchase: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='29'
            height='30'
            viewBox='0 0 29 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M7.30001 25.6H4.90001C4.23761 25.6 3.70001 25.0624 3.70001 24.4V17.2C3.70001 16.5376 4.23761 16 4.90001 16H7.30001C7.96241 16 8.50001 16.5376 8.50001 17.2V24.4C8.50001 25.0624 7.96241 25.6 7.30001 25.6Z'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15.7001 20.8H18.4997C19.0193 20.8 19.5245 20.632 19.9397 20.32L22.8389 18.1456C23.5625 17.6032 24.5753 17.6752 25.2149 18.3148V18.3148C25.9253 19.0252 25.9253 20.176 25.2149 20.8852L22.7273 23.3728C22.0577 24.0424 21.2033 24.4996 20.2745 24.6856L16.7585 25.3888C16.0613 25.528 15.3425 25.5112 14.6525 25.3384L11.4725 24.544C11.0921 24.448 10.7021 24.4 10.3097 24.4H8.50006'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15.7001 20.8H17.4821C18.4865 20.8 19.3001 19.9864 19.3001 18.982V18.6184C19.3001 17.7844 18.7325 17.0572 17.9237 16.8556L15.1733 16.168C14.7257 16.0564 14.2673 16 13.8065 16V16C12.6941 16 11.6057 16.3288 10.6805 16.9468L8.50006 18.4'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M20.7026 6.58629C22.0326 7.91626 22.0326 10.0726 20.7026 11.4025C19.3726 12.7325 17.2163 12.7325 15.8863 11.4025C14.5564 10.0726 14.5564 7.91626 15.8863 6.58629C17.2163 5.25631 19.3726 5.25631 20.7026 6.58629'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M17.6572 5.65005C17.5456 4.97205 17.236 4.32045 16.7128 3.79725C15.3832 2.46765 13.2268 2.46765 11.8972 3.79725C10.5676 5.12685 10.5676 7.28325 11.8972 8.61285C12.7252 9.44085 13.8724 9.75165 14.9416 9.54885'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconWalletConnect: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='30'
            height='18'
            viewBox='0 0 30 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6.23887 3.51839C11.042 -1.1728 18.8389 -1.1728 23.642 3.51839L24.2202 4.0873C24.4627 4.32047 24.4627 4.70285 24.2202 4.93601L22.243 6.86658C22.1218 6.98782 21.9259 6.98782 21.8047 6.86658L21.0119 6.09249C17.6544 2.81891 12.2264 2.81891 8.86892 6.09249L8.02022 6.92254C7.89897 7.04378 7.70312 7.04378 7.58188 6.92254L5.60467 4.99197C5.36219 4.75881 5.36219 4.37642 5.60467 4.14326L6.23887 3.51839ZM27.7363 7.5101L29.499 9.22616C29.7415 9.45933 29.7415 9.84171 29.499 10.0749L21.5622 17.8251C21.3197 18.0583 20.928 18.0583 20.6948 17.8251L15.0617 12.3225C15.0057 12.2666 14.9031 12.2666 14.8472 12.3225L9.214 17.8251C8.97151 18.0583 8.5798 18.0583 8.34664 17.8251L0.381877 10.0749C0.13939 9.84171 0.13939 9.45933 0.381877 9.22616L2.14457 7.5101C2.38706 7.27694 2.77877 7.27694 3.01193 7.5101L8.64509 13.0127C8.70105 13.0687 8.80364 13.0687 8.8596 13.0127L14.4928 7.5101C14.7352 7.27694 15.127 7.27694 15.3601 7.5101L20.9933 13.0127C21.0492 13.0687 21.1518 13.0687 21.2078 13.0127L26.8409 7.5101C27.1021 7.27694 27.4938 7.27694 27.7363 7.5101Z'
                fill='white'
            />
        </svg>
    )
}

export const IconSell: React.FC = (): React.ReactElement => {
    return (
        <svg
            width='30'
            height='30'
            viewBox='0 0 30 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle
                cx='17.8681'
                cy='20.2691'
                r='5.33555'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.8001 10.4784C14.8001 11.6723 12.3821 12.6393 9.39895 12.6393C6.41475 12.6393 3.9967 11.6723 3.9967 10.4784'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.39787 16.9611C6.41475 16.9611 3.9967 15.9941 3.9967 14.8002'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.7991 6.15656H14.8001V12.6393'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.39786 21.2828C6.41473 21.2828 3.99561 20.3158 3.99561 19.1219'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <ellipse
                cx='9.39786'
                cy='6.15657'
                rx='5.40225'
                ry='2.1609'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M3.99557 6.15656V19.122'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M23.2036 5.19617V11.1987'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M25.6046 7.59717L23.2036 5.19617L20.8026 7.59717'
                stroke='white'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}

export const IconReward: React.FC = () => {
    return (
        <svg
            width='25'
            height='26'
            viewBox='0 0 25 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M14.501 15.9014V21.6041C14.502 22.3774 16.0684 23.0045 18.0019 23.0045C19.9355 23.0045 21.5019 22.3774 21.5029 21.6041V15.9014'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.502 18.7529C14.502 19.5261 16.0694 20.1533 18.0029 20.1533C19.9364 20.1533 21.5039 19.5261 21.5039 18.7529'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.49756 11.9992H7.49798'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.4993 11.9992H11.4997'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.49756 15.0012H7.49798'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.4993 15.0012H11.4997'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.49756 11.9992H7.49798'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.4993 11.9992H11.4997'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.49756 18.0022H7.49798'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.4993 18.0022H11.4997'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.49756 18.0022H7.49798'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.4993 18.0022H11.4997'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M18.5026 8.99826H3.49634'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M11.4997 21.0032H5.49717C4.39214 21.0032 3.49634 20.1074 3.49634 19.0023V5.99693C3.49634 4.8919 4.39214 3.99609 5.49717 3.99609H16.5018C17.6068 3.99609 18.5026 4.8919 18.5026 5.99693V11.9994'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.49756 11.9992H7.49798'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.4993 11.9992H11.4997'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.501 11.9992H15.5014'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.502 15.9014C14.502 16.6746 16.0694 17.3017 18.0029 17.3017C19.9365 17.3017 21.5029 16.6746 21.5029 15.9014C21.5029 15.1271 19.9345 14.501 18.0019 14.501C16.0694 14.501 14.502 15.1281 14.501 15.9014'
                stroke='#D4D4D4'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    )
}
