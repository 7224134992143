import { observer } from 'mobx-react-lite'
import { Navigate, Outlet } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import AuthStore from 'store/AuthStore'

const AuthProvider: React.FC = (): React.ReactElement => {
	const isAuth: boolean = AuthStore.isAuth

	return <>{isAuth ? <Outlet /> : <Navigate to={AppRoutes.auth.signIn} />}</>
}
export default observer(AuthProvider)
