import { Link } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import s from './index.module.scss'

const Footer: React.FC = (): React.ReactElement => {
	return (
		<div className={s.wrapper}>
			<div className={s.links}>
				<Link to={AppRoutes.faq}>faq</Link>
				<Link to={AppRoutes.blog}>blog</Link>
				<Link to={AppRoutes.about}>about</Link>
			</div>

			<Link to={AppRoutes.support}>
				<a className={s.supportLink}>support</a>
			</Link>

			<p className={s.sign}>By CrispMind</p>
		</div>
	)
}
export default Footer
