import {IconHistory, IconImport} from 'com/Icons'
import ActionButton from 'com/UI/action/ActionButton'
import ActionLink from 'com/UI/action/ActionLink'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {AppRoutes} from 'routes/AppRoutes'
import styles from './styles.module.scss'
import ImportModal from "com/shared/Modals/Import";
import ActionSliderContainer from "com/UI/action/ActionSliderContainer";
import { SwiperSlide } from 'swiper/react'
import CurrenciesBalances from "./CurrenciesBalances";

const DashboardButtons: React.FC = (): React.ReactElement => {
    const {t} = useTranslation(['dashboard'])
    const [burnOpen, setImportOpen] = useState(false)

    return (
        <div className={styles.wrapper}>
            <div>
                <ActionSliderContainer slideToShow={4.1}>
                    <SwiperSlide>
                        <ActionButton
                            label={t('actionLinks.import')}
                            icon={<IconImport/>}
                            size='medium'
                            className='border-w-116'
                            onClick={() => setImportOpen(true)}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <ActionLink
                            label={t('actionLinks.history')}
                            icon={<IconHistory/>}
                            link={AppRoutes.banknotes.history}
                            size='medium'
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <ActionLink
                            label={t('actionLinks.history')}
                            icon={<IconHistory/>}
                            link={AppRoutes.banknotes.history}
                            size='medium'
                            disabled={true}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <ActionLink
                            label={t('actionLinks.history')}
                            icon={<IconHistory/>}
                            link={AppRoutes.banknotes.history}
                            size='medium'
                            disabled={true}
                        />
                    </SwiperSlide>
                </ActionSliderContainer>
            </div>

            <CurrenciesBalances/>

            <ImportModal
                isOpen={burnOpen}
                onClose={() => {
                    setImportOpen(false)
                }}
            />
        </div>
    )
}
export default DashboardButtons
