import { useTransition } from '@react-spring/web'
import { observer } from 'mobx-react-lite'
import { ModalsName } from 'models/Modals'
import AuthStore from 'store/AuthStore'
import ModalsStore from 'store/ModalsStore'
import MobileMenuComponent from './MobileMenuComponent'

const MobileMenu: React.FC = (): React.ReactElement => {
	const transition = useTransition(ModalsStore.get(ModalsName.mobileMenu), {
		from: { right: '-100%' },
		enter: { right: '0' },
		leave: { right: '-100%' }
	})

	return (
		<MobileMenuComponent
			isAuth={AuthStore.isAuth}
			transition={transition}
		/>
	)
}
export default observer(MobileMenu)
