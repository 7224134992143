import { useMediaQuery } from 'hooks/useMediaQuery'
import { Mousewheel } from 'swiper'
import { Swiper } from 'swiper/react'
import { MEDIA_QUERY_MOBILE } from 'utils/constants/MediaQueryConstants'
import styles from './styles.module.scss'
import 'swiper/css'

interface Props {
    children: React.ReactNode
    slideToShow?: number
}

const ActionSliderContainer: React.FC<Props> = (
    props: Props
): React.ReactElement => {
    const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE)

    if (isMobile) {
        return <div className={styles.wrapper}>{props.children}</div>
    }

    return (
        <Swiper
            modules={[Mousewheel]}
            mousewheel
            spaceBetween={12}
            slidesPerView={props.slideToShow}
        >
            {props.children}
        </Swiper>
    )
}

export default ActionSliderContainer
