import { makeAutoObservable } from 'mobx'
import { ModalsName } from 'models/Modals'

class ModalsStore {
	state: {
		[key in ModalsName]: boolean
	} = { langList: false, mobileMenu: false, mobileLangList: false }

	constructor() {
		makeAutoObservable(this)
	}

	set(key: ModalsName, value: boolean) {
		this.state[key] = value
	}

	get(key: ModalsName) {
		return this.state[key]
	}

	toggle(key: ModalsName) {
		this.state[key] = !this.state[key]
	}
}

export default new ModalsStore()
