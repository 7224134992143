import { useConfig } from 'hooks/useConfig'

interface Props {
    ticker: string
    className?: string
}

const CurrencyImage: React.FC<Props> = (props: Props): React.ReactElement => {
    const { ticker, className } = props
    const { resourcesUrl } = useConfig()
    return (
        <img
            className={className}
            src={`${resourcesUrl}/icons/${ticker.toLowerCase()}.svg`}
            alt={ticker}
        />
    )
}
export default CurrencyImage
