import { useNavigate } from 'react-router-dom'
import { TFunction } from 'react-i18next'
import Loader from 'com/UI/Loader'
import FormTitle from 'com/UI/form/FormTitle'
import Field from 'com/shared/form/Field'
import ShowPassword from 'com/shared/form/ShowPassword'
import s from './component.module.scss'
import React from "react";
import {SuccessIcon} from "../../../../com/Icons/NotificationStatuses";
import {AppRoutes} from "../../../../routes/AppRoutes";

interface Props {
	isLoading: boolean
	t: TFunction
}

const RegistrationComponent: React.FC<Props> = (
	props: Props
): React.ReactElement => {
	const {
		isLoading,
		t
	} = props
	
	let navigate = useNavigate();
	const routeChange = () =>{
		navigate(AppRoutes.auth.signIn);
	}
	return (
		<>
			<FormTitle>{t('register.title')}</FormTitle>
			<div className={s.form}>
				
				<h2 className={s.colorWhite}><SuccessIcon /> {t('register.success')}</h2>
				
				<div className={s.actions}>
					<button
						className={s.nextButton}
						onClick={routeChange}
						type='button'
					>
						{isLoading ? (
							<Loader size='small' />
						) : (
							t('buttons.gotoSignIn', { ns: 'common' })
						)}
					</button>
				</div>
			</div>
		</>
	)
}
export default RegistrationComponent
