import $api from 'api'
import {AxiosResponse} from 'axios'
import {ApiRoutes} from 'routes/ApiRoutes'
import {ResponseValidate} from 'models/ResponseValidate'
import {
    ChangePasswordArgs,
    CheckCodeArgs,
    CheckCodeResponse, ResetPasswordArgs, ResetPasswordResponse
} from './PasswordResetService.types'

export default class PasswordResetService {
    static async request(args: ResetPasswordArgs): Promise<AxiosResponse<ResetPasswordResponse>> {
        return $api.post<ResetPasswordResponse>(ApiRoutes.passwordReset.request, args)
    }

    static async checkCode(
        args: CheckCodeArgs
    ): Promise<AxiosResponse<CheckCodeResponse>> {
        return $api.post<CheckCodeResponse>(
            ApiRoutes.passwordReset.checkCode,
            args
        )
    }


    static async changePassword(
        args: ChangePasswordArgs
    ): Promise<AxiosResponse<ResponseValidate>> {
        return $api.post<ResponseValidate>(ApiRoutes.passwordReset.changePassword, args)
    }
}
