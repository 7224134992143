export const LSKeyLang = 'i18nextLng'
export const LSKeyRegLogin = 'regLogin'
export const LSKeyRegKey = 'regKey'
export const LSKeyAccessToken = 'access_token'
export const LSKeyRefreshToken = 'refresh_token'

export const LSKeyUserData = 'user_data'
export const LSKeyAccessTokenExp = 'access_token_exp'
export const LSKeyAuthEmail = 'authEmail'
export const LSKey2FA = 'auth_2fa_key'
export const LSKeyPasswordResetEmail = 'password_reset_email'
export const LSKeyPaswordResetSK = 'password_reset_sk'
export const LSKeyRefValue = 'signup_ref_value'

export const LSLoginValue = 'signup_login'
export const LSUserPwd = 'signup_user_pwd'
export const LSUserSeedPhrase = 'signup_seed_phrase'

export enum LsKeys {
    referrer = "referrer"
}