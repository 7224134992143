import { LsKeys } from 'constants/LocalStorageConstants'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import AuthService from 'services/Auth/AuthService'
import LocalStorageService from 'services/LocalStorageService'
import AuthStore from 'store/AuthStore'
import { IncludedDomain } from './types'

const includedDomains: IncludedDomain[] = [
	{
		domain: 'https://localhost:3004',
		redirectPage: 'otk-auth'
	},
	{
		domain: 'https://test.wallet.tectum.io',
		redirectPage: 'otk-auth'
	},
    {
		domain: 'https://test.wallet.softnote.com',
		redirectPage: 'otk-auth'
	},
    {
		domain: 'https://wallet.softnote.com',
		redirectPage: 'otk-auth'
	},
    {
		domain: 'https://dev.wallet.softnote.com',
		redirectPage: 'otk-auth'
	}
]

const AppRedirectAfterAuth: React.FC = (): React.ReactElement => {
	const redirectWithOtk = async (includedDomain: IncludedDomain) => {
		try {
			const result = await AuthService.createOtk()
			const baseRedirectUrl = `${includedDomain.domain}/${includedDomain.redirectPage}`
			const redirectUrl = `${baseRedirectUrl}?otk=${result.data.payload.otk}`
			LocalStorageService.remove(LsKeys.referrer)
			window.location.href = redirectUrl
		} catch (error) {
			console.log('Error in redirectWithOtk', error)
		}
	}

	useEffect(() => {
		const referrer = LocalStorageService.get(LsKeys.referrer)

		if (referrer) {
			for (const includedDomain of includedDomains) {
				if (
					referrer.includes(includedDomain.domain) &&
					AuthStore.isAuth &&
					!AuthStore.isLoading
				) {
					redirectWithOtk(includedDomain)
				}
			}
		}
	}, [AuthStore.isLoading, AuthStore.isAuth])

	useEffect(() => {
		if (document.referrer) {
			LocalStorageService.set(LsKeys.referrer, document.referrer)
		}
	}, [])

	return <></>
}
export default observer(AppRedirectAfterAuth)
