import IconPDF from 'assets/icons/files/pdf_icon.svg'
import IconDownload from 'assets/icons/form/download_icon.svg'
import PrivacyPolicySign from 'com/UI/PrivacyPolicySign'
import s from './component.module.scss'

interface Props {}

const PrivacyPolicyPageComponent: React.FC<Props> = (
	props: Props
): React.ReactElement => {
	return (
		<section className={s.wrapper}>
			<div className={s.container}>
				<h1 className={s.title}>Privacy Policy CrispMind Ltd</h1>
				<div className={s.block}>
					<p>
						At CrispMind, accessible from Tectum.io, one of our main
						priorities is the privacy of our visitors. This Privacy
						Policy document contains types of information that is
						collected and recorded by CrispMind and how we use it.
						<br />
						<br />
						If you have additional questions or require more
						information about our Privacy Policy, do not hesitate to
						contact us.
						<br />
						<br />
						This Privacy Policy applies only to our online
						activities and is valid for visitors to our website with
						regards to the information that they shared and/or
						collect in CrispMind. This policy is not applicable to
						any information collected offline or via channels other
						than this website.
					</p>
				</div>

				<div className={s.block}>
					<span>CONSENT</span>
					<p>
						By using our website, you hereby consent to our Privacy
						Policy and agree to its terms.
					</p>
				</div>

				<div className={s.block}>
					<span>INFORMATION WE COLLECT</span>
					<p>
						The personal information that you are asked to provide,
						and the reasons why you are asked to provide it, will be
						made clear to you at the point we ask you to provide
						your personal information.
						<br />
						<br />
						If you contact us directly, we may receive additional
						information about you such as your name, email address,
						phone number, the contents of the message and/or
						attachments you may send us, and any other information
						you may choose to provide.
						<br />
						<br />
						When you register for an Account, we may ask for your
						contact information, including items such as name,
						company name, address, email address, and telephone
						number.
					</p>
				</div>

				<div className={s.block}>
					<span>INFORMATION WE COLLECT</span>
					<p>
						We use the information we collect in various ways,
						including to:
						<br />
						<br />
						<ul>
							<li>Provide, operate, and maintain our website</li>
							<li>
								Improve, personalize, and expand our website
							</li>
							<li>
								Understand and analyze how you use our website
							</li>
							<li>
								Develop new products, services, features, and
								functionality
							</li>
							<li>
								Communicate with you, either directly or through
								one of our partners, including for customer
								service, to provide you with updates and other
								information relating to the website, and for
								marketing and promotional purposes
							</li>
							<li>Send you emails</li>
							<li>Find and prevent fraud</li>
						</ul>
					</p>
				</div>

				<div className={s.block}>
					<span>COOKIES AND WEB BEACONS</span>
					<p>
						Like any other website, CrispMind uses ‘cookies’. These
						cookies are used to store information including
						visitors’ preferences, and the pages on the website that
						the visitor accessed or visited. The information is used
						to optimize the users’ experience by customizing our web
						page content based on visitors’ browser type and/or
						other information.
						<br />
						<br />
						For more general information on cookies, please read the
						“Cookies” article from the Privacy Policy Generator.
					</p>
				</div>

				<div className={s.block}>
					<span>ADVERTISING PARTNER’S PRIVACY POLICIES</span>
					<p>
						You may consult this list to find the Privacy Policy for
						each of the advertising partners of CrispMind.
						<br />
						<br />
						Third-party ad servers or ad networks uses technologies
						like cookies, JavaScript, or Web Beacons that are used
						in their respective advertisements and links that appear
						on CrispMind, which are sent directly to users’ browser.
						They automatically receive your IP address when this
						occurs. These technologies are used to measure the
						effectiveness of their advertising campaigns and/or to
						personalize the advertising content that you see on
						websites that you visit.
						<br />
						<br />
						Note that Tectum has no access to or control over these
						cookies that are used by third-party advertisers.
					</p>
				</div>

				<div className={s.block}>
					<span>THIRD-PARTY PRIVACY POLICIES</span>
					<p>
						CrispMind’s Privacy Policy does not apply to other
						advertisers or websites. Thus, we are advising you to
						consult the respective Privacy Policies of these
						third-party ad servers for more detailed information. It
						may include their practices and instructions about how
						to opt out of certain options.
						<br />
						<br />
						You can choose to disable cookies through your
						individual browser options. To know more detailed
						information about cookie management with specific web
						browsers, it can be found on the browsers’ respective
						websites.
					</p>
				</div>

				<div className={s.block}>
					<span>
						NOTICE FOR CALIFORNIA RESIDENTS / CCPA PRIVACY RIGHTS
						(DO NOT SELL MY PERSONAL INFORMATION)
					</span>
					<p>
						Effective January 1, 2020, California residents are
						protected under the California Consumer Privacy Act
						(CCPA). We provide data protection terms that meet this
						as well as the EU and UK GDPR. The “Shine The Light” law
						(California Civil Code Section 1798.83) also guarantees
						you the ability to request and/or remove your data free
						of charge the information about the categories of
						personal information disclosed to third parties for
						direct marketing, as well as the names and addresses of
						third parties.
					</p>
				</div>

				<div className={s.block}>
					<span>
						UNDER THE CCPA, AMONG OTHER RIGHTS, CALIFORNIA CONSUMERS
						HAVE THE RIGHT TO:
					</span>
					<p>
						Request that a business that collects a consumer’s
						personal data disclose the categories and specific
						pieces of personal data that a business has collected
						about consumers.
						<br />
						<br />
						Request that a business deletes any personal data about
						the consumer that a business has collected.
						<br />
						<br />
						Request that a business that sells a consumer’s personal
						data, not sell the consumer’s personal data.
						<br />
						<br />
						If you are a California resident and would like to make
						a request, we have one month to respond to you. If you
						would like to exercise any of these rights, please
						contact us. Note that we will only accept forms asking
						for removal if the information provided matches the
						information to be removed and that we reserve the right
						to verify your identity before disclosing any.
					</p>
				</div>

				<div className={s.block}>
					<span>GDPR DATA PROTECTION RIGHTS</span>
					<p>
						We would like to make sure you are fully aware of all of
						your data protection rights. Every user is entitled to
						the following:
						<br />
						<br />
						The right to access – You have the right to request
						copies of your personal data. We may charge you a small
						fee for this service.
						<br />
						<br />
						The right to rectification – You have the right to
						request that we correct any information you believe is
						inaccurate. You also have the right to request that we
						complete the information you believe is incomplete.
						<br />
						<br />
						The right to erasure – You have the right to request
						that we erase your personal data, under certain
						conditions.
						<br />
						<br />
						The right to restrict processing – You have the right to
						request that we restrict the processing of your personal
						data, under certain conditions.
						<br />
						<br />
						The right to object to processing – You have the right
						to object to our processing of your personal data, under
						certain conditions.
						<br />
						<br />
						The right to data portability – You have the right to
						request that we transfer the data that we have collected
						to another organization, or directly to you, under
						certain conditions.
						<br />
						<br />
						If you make a request, we have one month to respond to
						you. If you would like to exercise any of these rights,
						please contact us.
					</p>
				</div>

				<div className={s.block}>
					<span>CHILDREN’S INFORMATION</span>
					<p>
						Another part of our priority is adding protection for
						children while using the internet. We encourage parents
						and guardians to observe, participate in, and/or monitor
						and guide their online activity.
						<br />
						<br />
						CrispMind does not knowingly collect any Personal
						Identifiable Information from children under the age of
						13. If you think that your child provided this kind of
						information on our website, we strongly encourage you to
						contact us immediately and we will do our best efforts
						to promptly remove such information from our records.
						<br />
						<br />
						We may update our Privacy Policy from time to time.
						Thus, you are advised to review this page periodically
						for any changes. We will notify users of any changes by
						posting the new Privacy Policy on this page. You will
						receive an email if an update changes what you might
						have consented to.
					</p>
				</div>

				<a
					href='/files/Privacy-Policy-CrispMind-2022.06.21.pdf'
					className={s.pdfDownload}
					download
				>
					<img src={IconPDF} alt='PDF' />
					<p>Privacy-Policy-CrispMind-2022.06.21</p>
					<img src={IconDownload} alt='Download PDF' />
				</a>

				<PrivacyPolicySign />
			</div>
		</section>
	)
}
export default PrivacyPolicyPageComponent
