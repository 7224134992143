import { useTransition } from '@react-spring/web'
import useClickOutside from 'hooks/useClickOutside'
import { observer } from 'mobx-react-lite'
import { MutableRefObject, useRef, useState } from 'react'
import AuthStore from 'store/AuthStore'
import HeaderComponent from './HeaderComponent'

const Header: React.FC = (): React.ReactElement => {
	const [isOpenUserInfo, setOpenUserInfo] = useState<boolean>(false)

	const transition = useTransition(isOpenUserInfo && AuthStore.isAuth, {
		from: { opacity: '0' },
		enter: { opacity: '1' },
		leave: { opacity: '0' },
		config: {
			duration: 250
		}
	})

	const handleClickUserInfo = (): void => {
		setOpenUserInfo(prev => !prev)
	}

	const refUserCard = useRef() as MutableRefObject<HTMLDivElement>

	useClickOutside(refUserCard, () => setOpenUserInfo(false))

	return (
		<HeaderComponent
			isAuth={AuthStore.isAuth}
			transition={transition}
			handleClickUserInfo={handleClickUserInfo}
			refUserCard={refUserCard}
		/>
	)
}
export default observer(Header)
