import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	LSKeyPasswordResetEmail,
	LSKeyPaswordResetSK
} from 'constants/LocalStorageConstants'
import LocalStorageService from 'services/LocalStorageService'
import { AppRoutes } from 'routes/AppRoutes'
import { PasswordResetSteps } from 'models/PasswordResetSteps'
import AuthStore from 'store/AuthStore'
import PasswordResetStore from 'store/PasswordResetStore'
import PasswordResetComponent from './component'

const PasswordResetPage: React.FC = (): React.ReactElement => {
	const navigate = useNavigate()

	useEffect(() => {
		if (AuthStore.isAuth) {
			navigate(AppRoutes.home)
		}
	}, [navigate, AuthStore.isAuth])

	useEffect(() => {
		const resetEmail = LocalStorageService.get(LSKeyPasswordResetEmail)
		const SK = LocalStorageService.get(LSKeyPaswordResetSK)

		if (resetEmail) PasswordResetStore.setStep(PasswordResetSteps.checkCode)
		if (SK) PasswordResetStore.setStep(PasswordResetSteps.resetPassword)
	}, [])

	return <PasswordResetComponent step={PasswordResetStore.getStep()} />
}
export default observer(PasswordResetPage)
