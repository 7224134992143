import {User, UserSecureMode} from 'models/User'
import React from "react";
import DashboardButtons from "./DashboardButtons";
import CurrenciesBalances from "./DashboardButtons/CurrenciesBalances";
import styles from './component.module.scss'

interface Props {
    user: User
}

const DashboardPageComponent: React.FC<Props> = (
    props: Props
): React.ReactElement => {
    return (
        <div className={styles.wrapper}>
            <div>
                <DashboardButtons/>
            </div>
        </div>
    )
}
export default DashboardPageComponent
