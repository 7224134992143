import IconCheck from 'assets/icons/form/check.svg'
import s from './index.module.scss'

interface Props {
	checked: boolean
	onClick: () => void
}

const Checkbox: React.FC<Props> = (props: Props): React.ReactElement => {
	const { checked, onClick } = props
	return (
		<div className={s.wrapper}>
			<input type='checkbox' checked={checked} onClick={onClick} />
			<img src={IconCheck} alt='' />
			<span></span>
		</div>
	)
}
export default Checkbox
