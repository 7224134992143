import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LSKeyRegLogin, LSKeyRegKey } from 'constants/LocalStorageConstants'
import LocalStorageService from 'services/LocalStorageService'
import { AppRoutes } from 'routes/AppRoutes'
import { SignUpSteps } from 'models/SignUpSteps'
import AuthStore from 'store/AuthStore'
import SignUpStore from 'store/SignUpStore'
import SignUpPageComponent from './component'

const SignUpPage: React.FC = (): React.ReactElement => {
	const navigate = useNavigate()

	useEffect(() => {
		if (AuthStore.isAuth) {
			navigate(AppRoutes.home)
		}
	}, [navigate, AuthStore.isAuth])

	useEffect(() => {
		const regKey = LocalStorageService.get(LSKeyRegKey)
		const regEmail = LocalStorageService.get(LSKeyRegLogin)
		if (regKey) SignUpStore.setStep(SignUpSteps.registration)
		if (regEmail) SignUpStore.setStep(SignUpSteps.checkCode)
	}, [])

	return <SignUpPageComponent step={SignUpStore.getStep()} />
}
export default observer(SignUpPage)
