import {makeAutoObservable} from 'mobx'
import {
    LSKeyPasswordResetEmail,
    LSKeyPaswordResetSK
} from 'constants/LocalStorageConstants'
import LocalStorageService from 'services/LocalStorageService'
import PasswordResetService from 'services/PasswordReset/PasswordResetService'
import {
    CheckCodeResponse, ResetPasswordResponse
} from 'services/PasswordReset/PasswordResetService.types'
import {PasswordResetSteps} from 'models/PasswordResetSteps'
import {ResponseValidate, ResponseStatuses} from 'models/ResponseValidate'
import {AxiosResponse} from "axios";

class PasswordResetStore {
    step: PasswordResetSteps = PasswordResetSteps.request
    login: string = ''

    constructor() {
        makeAutoObservable(this)
    }

    setStep(step: PasswordResetSteps): void {
        this.step = step
    }

    getStep(): PasswordResetSteps {
        return this.step
    }

    setLogin(login: string): void {
        this.login = login
    }

    getLogin(): string {
        return this.login
    }

    async checkCode(
        email: string,
        code: string
    ): Promise<CheckCodeResponse | void> {
        try {
            const result = await PasswordResetService.checkCode({
                email,
                code
            })

            const {hasError, payload} = result.data

            if (!hasError && payload.sk) {
                this.setSK(payload.sk)
            }

            return result.data
        } catch (error) {
            console.log(error)
        }
    }

    async changePassword(password: string): Promise<ResponseValidate | void> {
        try {
            const sk = LocalStorageService.get(LSKeyPaswordResetSK)

            const result = await PasswordResetService.changePassword({
                sk,
                password
            })

            return result.data
        } catch (error) {
            console.log(error)
        }
    }

    private setSK(sk: string) {
        this.setStep(PasswordResetSteps.resetPassword)
        LocalStorageService.set(LSKeyPaswordResetSK, sk)
        LocalStorageService.remove(LSKeyPasswordResetEmail)
    }
}

export default new PasswordResetStore()
