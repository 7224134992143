import { observer } from 'mobx-react-lite'
import AuthStore from 'store/AuthStore'
import UserCardComponent from './UserCardComponent'

const UserCard: React.FC = (): React.ReactElement => {
	const handleClickLogout = (): void => {
		AuthStore.logout()
	}

	return (
		<UserCardComponent
			user={AuthStore.user}
			handleClickLogout={handleClickLogout}
		/>
	)
}
export default observer(UserCard)
