export const AppRoutes = {
    home: '/',
    auth: {
        signUp: '/registration',
        signUpApprove: '/registration/approve',
        signIn: '/login',
        passwordReset: '/password-reset'
    },
    banknotes: {
        baseurl: '/banknotes/',
        history: '/banknotes/history'
    },
    confidentiality: {
        policy: '/policy'
    },
    products: '/',
    about: '/',
    blog: '/',
    faq: '',
    tectumWallet: '/',
    mintHouse: '/',
    exchange: '/',
    explorer: '/',
    settings: '/',
    support: '/',

    user: {
        security: '/user/security',
        support: '/user/support',
        enable3FA: '/user/enable3fa'
    }
}
