import {FormikProps, useFormik} from 'formik'
import {useCallback, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import PasswordResetStore from 'store/PasswordResetStore'
import RequestResetComponent from './component'
import {Values} from './types'
import {useNavigate} from 'react-router-dom'
import {AppRoutes} from "routes/AppRoutes";
import {PasswordResetSteps} from "models/PasswordResetSteps";
import PasswordResetService from "services/PasswordReset/PasswordResetService";

const RequestReset: React.FC<WithTranslation> = ({t}): React.ReactElement => {
    const [isLoading, setLoading] = useState<boolean>(false)
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)
    const [errorsValidate, setErrors] = useState<string[]>([])
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false)

    const navigate = useNavigate()
    const formik: FormikProps<Values> = useFormik<Values>({
        initialValues: {seedPhrase: '', token: ''} as Values,
        validateOnChange: validateOnChange,

        onSubmit: async (values: Values) => {

            console.log(values.token)
            console.log(values.seedPhrase)
            setLoading(true)

            await PasswordResetService.request({seedPhrase: values.seedPhrase, token: values.token})
                .then(res => {
                    setLoading(false)

                    if (res.data.hasError) {
                        setErrors(res.data.errorMsgs)
                        return;

                    }
                    PasswordResetStore.setLogin(res.data.login)
                    PasswordResetStore.setStep(PasswordResetSteps.resetPassword)

                })

                .catch(error => {
                    setErrors(error.response.data.errorMsgs)
                })

            setLoading(false)
        }
    })

    const handleSubmit = () => {
        setValidateOnChange(true)
        formik.handleSubmit()

        setRefreshReCaptcha(r => !r)
    }
    const handleClickBack = () => {
        navigate(AppRoutes.auth.signIn)
    }

    const onChangeCaptcha = useCallback(
        (value: string | null) => {
            formik.setFieldValue('token', value)
        },
        [refreshReCaptcha]
    )

    return (
        <RequestResetComponent
            formik={formik}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            handleClickBack={handleClickBack}
            errors={errorsValidate}
            t={t}
            refreshReCaptcha={refreshReCaptcha}
            onChangeCaptcha={onChangeCaptcha}
        />
    )
}
export default withTranslation(['auth', 'common'])(RequestReset)
