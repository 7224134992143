import cls from 'classnames'
import Loader from 'com/UI/Loader'
import s from './index.module.scss'

interface Props {
    children: React.ReactNode | string
    disabled?: boolean
    isLoading?: boolean
    className?: string
    onClick?: () => void
    type?: 'primary',
    size?: 'large' | 'medium' | 'small'
}

const Button: React.FC<Props> = (props: Props): React.ReactElement => {
    const {
        children, disabled, className, isLoading, 
        onClick, type,
        size = 'small'
    } = props

    const t = type ?? 'primary'

    let elClassName = cls(s.button, className,size)

    if (disabled) elClassName = cls(elClassName, s.disabled)
    if (t === 'primary') elClassName = cls(elClassName, s.primary)

    return (
        <button onClick={disabled ? () => {
        } : onClick} className={elClassName}>
            {isLoading ? <Loader size='small'/> : children}
        </button>
    )
}
export default Button
