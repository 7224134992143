const ReactSpringModalOptions = {
    from: { opacity: 0, transform: 'translateY(-40px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-40px)' }
}

export interface ReactSpringModalProperties {
    opacity: number
    transform: string
}
export const ReactSpringSlideOptions = {
    from: { top: '100vh' },
    enter: { top: '0vh' },
    leave: { top: '100vh' }
}

export interface ReactSpringSlideProperties {
    opacity: number
    transform: string
}
export const ReactSpringDatePickerOptions = {
    from: { top: '-2000px' },
    enter: { top: '32px' },
    leave: { top: '-2000px' }
}

export interface ReactSpringDatePickerOption {
    top: string
}

export { ReactSpringModalOptions }
