import styles from './styles.module.scss'
import {useState} from "react";

interface Props {
    name: string
    value: string | number
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    placeholder?: string
    label?: any
    readOnly?: boolean
}

const TextArea: React.FC<Props> = (props: Props): React.ReactElement => {
    const {name, value, onChange, placeholder, label, readOnly} = props
    return (
        <div className={styles.wrapper}>
            {label && <label>{label}</label>}
            {readOnly ?
                <textarea
                    id={name}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    readOnly
                    className={styles.input}
                /> :
                <textarea
                    name={name}
                    id={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    className={styles.input}
                />
            }
        </div>
    )
}
export default TextArea
