import IconUserAvatar from 'assets/icons/user/avatar.svg'
import { User } from 'models/User'
import { IconUserProAccount, IconUserVerification } from 'com/Icons'
import IconLogout from 'com/Icons/Buttons/IconLogout'
import styles from './UserCardComponent.module.scss'

interface Props {
	user: User | null
	handleClickLogout: () => void
}

const UserCardComponent: React.FC<Props> = (
	props: Props
): React.ReactElement => {
	const { user, handleClickLogout } = props

	if (!user) {
		return <></>
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.avatar}>
				<img src={IconUserAvatar} alt='' />
			</div>

			<div className={styles.info}>
				<div className={styles.info__header}>
					<div className={styles.userID}>vault ID: {user.id}</div>

					<div className={styles.userVerification}>
						<IconUserVerification />
					</div>

					<div className={styles.userProAccount}>
						<IconUserProAccount />
					</div>
				</div>

				<div className={styles.info__login}>{user.login}</div>
			</div>

			<div className={styles.rightSide}>
				<button onClick={handleClickLogout} className={styles.logoutButton}>
					<IconLogout />
				</button>
			</div>
		</div>
	)
}
export default UserCardComponent
