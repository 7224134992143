import {makeAutoObservable} from 'mobx'
import BanknotesService from "../../services/Banknotes/BanknotesService";
import {BalanceInfo, SoftNoteCurrency} from "../../services/Banknotes/types";

export interface Cryptocurrency {
    id: number
    network: string
    protocol: string
    ticker: string
    name: string
    decimalPlaces: number
    key: string
}

export interface BalanceBanknote extends Cryptocurrency {
    count: number | null
    amount: number
}

class BanknotesStore {
    balances: BalanceBanknote[] = []
    isLoadingBalances: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    private setBalances(balances: BalanceBanknote[]): void {
        this.balances = balances
    }


    private setBalancesLoading(bool: boolean): void {
        this.isLoadingBalances = bool
    }

    async cacheBalances(): Promise<void> {
        try {
            this.setBalancesLoading(true)
            const response = await BanknotesService.balances()
            const {balances} = response.data
            this.setBalances(balances)
        } catch (error) {
            console.log('Error: cacheBalances: ', error)
        } finally {
            this.setBalancesLoading(false)
        }
    }
}

export default new BanknotesStore()
