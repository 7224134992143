import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'
import s from './index.module.scss'

interface Props {}

const FormTabs: React.FC<Props> = (props: Props): React.ReactElement => {
	const isActive = (data: { isActive: boolean }): string => {
		return data.isActive ? s.active : ''
	}

	const { t } = useTranslation('common')

	return (
		<div className={s.tabs}>
			<NavLink to={AppRoutes.auth.signUp} className={isActive}>
				{t('buttons.signUp')}
			</NavLink>
			<NavLink to={AppRoutes.auth.signIn} className={isActive}>
				{t('buttons.signIn')}
			</NavLink>
		</div>
	)
}
export default FormTabs
