import styles from './styles.module.scss'

interface Props {
    type?: 'paragraph' | 'title'
}

const Skeleton: React.FC<Props> = (props: Props): React.ReactElement => {
    const { type = 'paragraph' } = props

    return (
        <div className={styles.wrapper}>
            {type === 'paragraph' && (
                <div className={styles.paragraph}>
                    <div />
                    <div />
                    <div />
                </div>
            )}
            {type === 'title' && (
                <div className={styles.title}>
                    <div />
                    <div />
                </div>
            )}
        </div>
    )
}
export default Skeleton
