import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { AppRoutes } from 'routes/AppRoutes'

const ProductsMenuList: React.FC = (): React.ReactElement => {
	const { t } = useTranslation('common')

	return (
		<>
			<Link to={AppRoutes.tectumWallet}>
				{t('menu.tectumBlockchain')}
			</Link>
			<Link to={AppRoutes.tectumWallet}>{t('menu.tectumWallet')}</Link>
			<Link to={AppRoutes.mintHouse}>{t('menu.tectumToken')}</Link>
			<Link to={AppRoutes.exchange}>{t('menu.tectumExplorer')}</Link>
			<Link to={AppRoutes.explorer}>{t('menu.tectumSoftnote')}</Link>
			<Link to={AppRoutes.settings}>{t('menu.3faAuth')}</Link>
		</>
	)
}
export default ProductsMenuList
